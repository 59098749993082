/**=====================
    1.1 Reset CSS start
==========================**/
.font-outfit {
  font-family: $font-outfit, $font-serif;
}

.f-light {
  color: rgba($theme-body-sub-title-color, 0.8);
}

svg {
  &.f-light {
    fill: $theme-body-sub-title-color;
    opacity: 0.8;
  }
}

.light-card {
  background-color: var(--light-background);
}

.light-background {
  background-color: var(--light-bg);
}

.icon-arrow-down,
.icon-arrow-up {
  &.icon-rotate {
    transform: rotate(-45deg);
    display: inline-block;
  }
}

/*====== Padding css starts ======*/
$i: 0;

@while $i<=50 {
  .p-#{$i} {
    padding:#{$i}px;
  }

  $i: $i+5;
}

/*====== Padding css ends ======*/

/*====== Padding-left css starts ======*/
$i: 0;

@while $i<=50 {
  .p-l-#{$i} {
    padding-left:#{$i}px;
  }

  $i: $i+5;

}

/*====== Padding-left css ends ======*/

/*====== Padding-top css starts ======*/
$i: 0;

@while $i<=50 {
  .p-t-#{$i} {
    padding-top:#{$i}px !important;
  }

  $i: $i+5;
}

/*====== Padding-top css ends ======*/


/*====== Padding-bottom css starts ======*/
$i: 0;

@while $i<=50 {
  .p-b-#{$i} {
    padding-bottom:#{$i}px !important;
  }

  $i: $i+5;
}

/*====== Padding-bottom css ends ======*/

/*====== Padding-right css starts ======*/
$i: 0;

@while $i<=50 {
  .p-r-#{$i} {
    padding-right:#{$i}px;
  }

  $i: $i+5;
}

/*====== Padding-right css ends ======*/


/*====== Margin css starts ======*/
$i: 0;

@while $i<=50 {
  .m-#{$i} {
    margin:#{$i}px !important;
  }

  $i: $i+5;
}

/*====== Margin css ends ======*/

/*====== Margin-top css starts ======*/
$i: 0;

@while $i<=50 {
  .m-t-#{$i} {
    margin-top:#{$i}px !important;
  }

  $i: $i+5;
}

/*====== Margin-top css ends ======*/

/*====== Margin-Bottom css starts ======*/
$i: 0;

@while $i<=50 {
  .m-b-#{$i} {
    margin-bottom:#{$i}px !important;
  }

  $i: $i+5;
}

/*====== Margin-Bottom css ends ======*/

/*====== Margin-left css starts ======*/
$i: 0;

@while $i<=50 {
  .m-l-#{$i} {
    margin-left:#{$i}px !important;
  }

  $i: $i+5;
}

/*====== Margin-left css ends ======*/

/*====== Margin-right css starts ======*/
$i: 0;

@while $i<=50 {
  .m-r-#{$i} {
    margin-right:#{$i}px;
  }

  $i: $i+5;
}

/*====== Margin-right css ends ======*/

/*====== Border-radius css starts ======*/
$i: 0;

@while $i<=10 {
  .b-r-#{$i} {
    border-radius:#{$i}px !important;
  }

  $i: $i+1;
}

/*====== Border-radius css ends ======*/


/*====== Font-size css starts ======*/
$i: 12;

@while $i<=100 {
  .f-#{$i} {
    font-size:#{$i}px !important;
  }

  $i: $i+2;
}

/*====== Font-size css ends ======*/


/*====== Font-weight css starts ======*/
$i: 100,
  300,
  500,
  400,
  600,
  700,
  900;

@each $val in $i {
  .f-w-#{$val} {
    font-weight: $val;
  }
}

/*====== Font-weight css ends ======*/


/*====== Font-style css starts ======*/
$i: normal,
  italic,
  oblique,
  initial,
  inherit;

@each $val in $i {
  .f-s-#{$val} {
    font-style: $val;
  }
}

/*====== Font-style css ends ======*/


/*====== Text-Decoration css starts ======*/

$i: overline,
  line-through,
  underline,
  dashed,
  blink,
  dotted,
  initial,
  none,
  dashed,
  wavy,
  inherit,
  double;

@each $val in $i {
  .text-#{$val} {
    text-decoration: $val;
  }
}

/*====== Text-Decoration css ends ======*/


/*====== Vertical-Align css starts ======*/
$i: baseline,
  sub,
  super,
  top,
  text-top,
  middle,
  bottom,
  text-bottom,
  initial,
  inherit;

@each $val in $i {
  .#{$val} {
    vertical-align: $val;
  }
}

/*====== Vertical-Align css ends ======*/


/*====== Position css starts ======*/

$i: static,
  absolute,
  fixed,
  relative,
  initial,
  inherit;

@each $val in $i {
  .p-#{$val} {
    position: $val;
  }
}

/*====== Position css ends ======*/

/*====== Float css starts ======*/
$i: left,
  right,
  none;

@each $val in $i {
  .f-#{$val} {
    float: $val;
  }
}

/*====== Float css ends ======*/


/*====== Overflow css starts ======*/
$i: hidden,
  visible,
  auto;

@each $val in $i {
  .o-#{$val} {
    overflow: $val;
  }
}

/*====== Overflow css ends ======*/


/*====== Image-sizes css starts ======*/
$i: 10;

@while $i<=100 {
  .img-#{$i} {
    width:#{$i}px !important;
  }

  .img-h-#{$i} {
    height:#{$i}px !important;
  }

  $i: $i+10;
}

/*====== Image-sizes css ends ======*/

/*======= Text css starts ===========*/
$text-name: primary,
  secondary,
  success,
  danger,
  info,
  tertiary,
  light,
  dark,
  warning;
$text-color : $primary-color,
$secondary-color,
$success-color,
$danger-color,
$info-color,
$tertiary-color,
$light-color,
$dark-color,
$warning-color;

@each $var in $text-name {
  $i: index($text-name, $var);

  .font-#{$var} {
    color: nth($text-color, $i) !important;
  }

  .stroke-#{$var} {
    stroke: nth($text-color, $i) !important;
  }
}

.font-primary {
  color: var(--theme-deafult) !important;
}

.font-secondary {
  color: var(--theme-secondary) !important;
}

/*======= Text css ends ===========*/


/*======= Label-color css starts  ======= */

.label {
  border-radius: 2px;
  color: $white;
  font-size: 12px;
  line-height: 1;
  margin-bottom: 0;
  text-transform: capitalize;
}

$label-name: theme,
  primary,
  secondary,
  success,
  danger,
  info,
  tertiary,
  light,
  dark,
  warning;
$label-color: $primary-color,
  $primary-color,
  $secondary-color,
  $success-color,
  $danger-color,
  $info-color,
  $tertiary-color,
  $light-color,
  $dark-color,
  $warning-color;

@each $var in $label-name {
  $i: index($label-name, $var);

  .label-#{$var} {
    background-color: nth($label-color, $i);
  }
}


/*======= Label-color css ends  ======= */


/*======= Badge-color css starts  ======= */

$badge-name: primary,
  secondary,
  success,
  danger,
  info,
  tertiary,
  light,
  dark,
  warning;
$badge-color: $primary-color,
  $secondary-color,
  $success-color,
  $danger-color,
  $info-color,
  $tertiary-color,
  $light-color,
  $dark-color,
  $warning-color;

@each $var in $badge-name {
  $i: index($badge-name, $var);

  .badge-#{$var} {
    background-color: nth($badge-color, $i);

    @if($badge-name=="light") {
      color: $theme-body-font-color  !important;
    }
  }
}

.badge-primary {
  background-color: var(--theme-deafult) !important;
}

.badge-secondary {
  background-color: var(--theme-secondary) !important;
}

/*======= Badge-color css end  ======= */


/*======= Background-color css starts  ======= */

$background-name: primary,
  secondary,
  success,
  danger,
  info,
  tertiary,
  light,
  dark,
  warning;
$background-color: $primary-color,
  $secondary-color,
  $success-color,
  $danger-color,
  $info-color,
  $tertiary-color,
  $light-color,
  $dark-color,
  $warning-color;

@each $var in $background-name {
  $i: index($background-name, $var);

  .bg-#{$var} {
    background-color: nth($background-color, $i) !important;
    color: $white;
  }

  .fill-#{$var} {
    fill: nth($background-color, $i) !important;
  }

  .bg-light-#{$var} {
    background-color: rgba(nth($background-color, $i), 0.2) !important;
    color: $white;
  }

  .b-light1-#{$var} {
    background-color: rgba(nth($background-color, $i), 0.1) !important;
    color: $background-name;
  }

  .background-light-#{$var} {
    background-color: rgba(nth($background-color, $i), 0.2) !important;
    color: nth($background-color, $i);
    transition: all .4s;

    &:hover {
      background-color: rgba(nth($background-color, $i), 1) !important;
      color: $white  !important;
      transition: all .4s;
    }
  }
}

.bg-primary {
  background-color: var(--theme-deafult) !important;
}

.bg-secondary {
  background-color: var(--theme-secondary) !important;
}

@each $badge-light-name,
$badge-light-color in (primary, $primary-color),
(secondary, $secondary-color),
(tertiary, $tertiary-color),
(success, $success-color),
(danger, $danger-color),
(info, $info-color),
(light, $badge-light-color),
(dark, $dark-color),
(warning, $warning-color) {
  .badge-light-#{$badge-light-name} {
    background-color: lighten($badge-light-color, 38%);
    color: $badge-light-color;

    &:hover {
      background-color: lighten($badge-light-color, 38%);
      color: $badge-light-color;
    }
  }

  .badge-light-#{$badge-light-name} {
    background-color: lighten($badge-light-color, 38%);
    color: $badge-light-color;

    &:hover {
      background-color: lighten($badge-light-color, 38%);
      color: $badge-light-color;
    }
  }
}

.badge-light-light {
  background-color: lighten($badge-light-color, 55%);
}

.badge-light-primary {
  background-color: rgba($primary-color, 0.1);
}

.badge-light-success {
  background-color: rgba($success-color, 0.15);
}

.badge-light-secondary {
  background-color: rgba($secondary-color, 0.08);
}

.badge-light-warning {
  background-color: rgba($warning-color, 0.1);
}

.badge-light-danger {
  background-color: rgba($danger-color, 0.1);
}
.badge-light-info {
  background-color: rgba($info-color, 0.1);
}
/*======= Background-color css end  ======= */

/*======= Font-color css starts  ======= */

$text-name: primary,
  secondary,
  success,
  danger,
  info,
  tertiary,
  light,
  dark,
  warning,
  google-plus,
  twitter,
  linkedin,
  fb;
$label-color: $primary-color,
  $secondary-color,
  $success-color,
  $danger-color,
  $info-color,
  $tertiary-color,
  $light-color,
  $dark-color,
  $warning-color,
  $google-plus,
  $twitter,
  $linkedin,
  $fb;

@each $var in $text-name {
  $i: index($text-name, $var);

  .txt-#{$var} {
    color: nth($label-color, $i) !important;
  }
}

.txt-primary {
  color: var(--theme-deafult) !important;
}

.txt-secondary {
  color: var(--theme-secondary) !important;
}

/*======= Font-color css end  ======= */


/*======= Button-color css starts  ======= */
// btn--color//
@keyframes push {
  50% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(1);
  }
}

@each $btn-name,
$btn-color in (primary, $primary-color),
(secondary, $secondary-color),
(success, $success-color),
(danger, $danger-color),
(tertiary, $tertiary-color),
(info, $info-color),
(light, $light-color),
(dark, $dark-color),
(warning, $warning-color) {
  .btn-#{$btn-name} {
    background-color: $btn-color  !important;
    border-color: $btn-color  !important;
    -webkit-tap-highlight-color: transparent;
    transform: translateZ(0);

    @if($btn-name=="info") {
      color: $white;

      &:hover,
      &:focus,
      &:active,
      &.active {
        color: $white;
      }
    }

    &.disabled,
    &:disabled {
      background-color: $btn-color  !important;
      border-color: $btn-color  !important;
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem lighten($btn-color, 30%);
    }
  }

  .btn-light1-#{$btn-name} {
    background-color: rgba($btn-color, 0.1) !important;
  }
}

.btn-light {
  background-color: rgba($primary-color, 0.10) !important;
  border-color: rgba($primary-color, 0.10) !important;
  border-style: solid !important;

  &:hover {
    background-color: rgba($primary-color, 1) !important;
    border-color: rgba($primary-color, 1) !important;
  }
}

.btn-primary {
  background-color: var(--theme-deafult) !important;
  border-color: var(--theme-deafult) !important;

  &.disabled,
  &:disabled {
    background-color: var(--theme-deafult) !important;
    border-color: var(--theme-deafult) !important;
  }
}

.btn-secondary {
  background-color: var(--theme-secondary) !important;
  border-color: var(--theme-secondary) !important;

  &.disabled,
  &:disabled {
    background-color: var(--theme-secondary) !important;
    border-color: var(--theme-secondary) !important;
  }
}

.btn-hover-effect {
  &:hover {
    animation: push 0.5s linear 1;
  }
}

/*======= Button-color css ends  ======= */
@each $btn-name,
$btn-color in (primary, $primary-color),
(secondary, $secondary-color),
(success, $success-color),
(danger, $danger-color),
(info, $info-color),
(tertiary, $tertiary-color),
(light, $light-color),
(dark, $dark-color),
(warning, $warning-color) {
  .btn-outline-#{$btn-name}-2x {
    border-width: 2px;
    border-color: $btn-color;
    color: $btn-color;
    background-color: transparent;

    &:hover,
    &:focus,
    &:active,
    &.active {
      color: white;
      background-color: darken($btn-color, 10%) !important;
      border-color: darken($btn-color, 10%) !important;
      box-shadow: none;
    }
  }
}

.btn-outline-primary-2x {
  border-color: var(--theme-deafult) !important;
  color: var(--theme-deafult) !important;

  &:hover {
    border-color: var(--theme-deafult) !important;
    background-color: var(--theme-deafult) !important;
    color: $white  !important;
  }
}

.btn-outline-secondary-2x {
  border-color: var(--theme-secondary) !important;
  color: var(--theme-secondary) !important;

  &:hover {
    border-color: var(--theme-secondary) !important;
    background-color: var(--theme-secondary) !important;
    color: $white  !important;
  }
}


// outline buttons//
@each $btn-name,
$btn-color in (primary, $primary-color),
(secondary, $secondary-color),
(success, $success-color),
(danger, $danger-color),
(info, $info-color),
(tertiary, $tertiary-color),
(light, $light-color),
(dark, $dark-color),
(warning, $warning-color) {
  .btn-outline-#{$btn-name} {
    border-color: $btn-color;
    color: $btn-color;
    background-color: transparent;
    border-style: solid;

    &.disabled {
      color: $btn-color;
    }

    @if($btn-name=="light") {
      color: $theme-body-font-color;
    }

    &:hover,
    &:focus,
    &:active,
    &.active {
      color: white;
      background-color: darken($btn-color, 10%) !important;
      border-color: darken($btn-color, 10%) !important;
    }
  }
}

.btn-outline-primary {
  border-color: var(--theme-deafult) !important;
  color: var(--theme-deafult) !important;

  &:hover {
    background-color: var(--theme-deafult) !important;
    border-color: var(--theme-deafult) !important;
    color: $white  !important;
  }
}

.btn-outline-secondary {
  border-color: var(--theme-secondary) !important;
  color: var(--theme-secondary) !important;

  &:hover {
    background-color: var(--theme-secondary) !important;
    border-color: var(--theme-secondary) !important;
    color: $white  !important;
  }
}

//active button css //

@each $btn-name,
$btn-color in (primary, $primary-color),
(secondary, $secondary-color),
(success, $success-color),
(danger, $danger-color),
(info, $info-color),
(tertiary, $tertiary-color),
(light, $light-color),
(dark, $dark-color),
(warning, $warning-color) {
  .btn-#{$btn-name}:not([disabled]):not(.disabled).active {
    background-color: darken($btn-color, 10%);
    border-color: darken($btn-color, 10%);
    box-shadow: none !important;

    &:hover,
    &:focus,
    &:active,
    &.active {
      color: white;
      background-color: darken($btn-color, 10%);
      border-color: darken($btn-color, 10%);
    }
  }
}

//* active button css end *//

@each $btn-name,
$btn-color in (primary, $primary-color),
(secondary, $secondary-color),
(success, $success-color),
(danger, $danger-color),
(info, $info-color),
(tertiary, $tertiary-color),
(light, $light-color),
(dark, $dark-color),
(warning, $warning-color) {

  .btn-outline-#{$btn-name}-2x:not([disabled]):not(.disabled).active {
    background-color: $btn-color;
    border-color: $btn-color;
    box-shadow: none !important;
    color: white;

    &:hover,
    &:focus,
    &:active,
    &.active {
      color: white;
      box-shadow: none !important;
      background-color: darken($btn-color, 10%) !important;
      border-color: darken($btn-color, 10%) !important;
    }
  }
}

/*======= Table-Border-Bottom-color css starts  ======= */

$border-name: primary,
  secondary,
  success,
  danger,
  info,
  light,
  tertiary,
  dark,
  warning;
$border-color: $primary-color,
  $secondary-color,
  $success-color,
  $danger-color,
  $info-color,
  $light-color,
  $tertiary-color,
  $dark-color,
  $warning-color;

@each $var in $border-name {
  $i: index($border-name, $var);

  table thead .border-bottom-#{$var} th,
  table tbody .border-bottom-#{$var} th,
  table tbody .border-bottom-#{$var} td {
    border-bottom: 1px dashed nth($border-color, $i) !important;
  }
}


/*======= Table-Border-Bottom-color css ends  ======= */


/*======= Table styling css starts  ======= */

$table-name: primary,
  secondary,
  success,
  danger,
  info,
  tertiary,
  light,
  dark,
  warning;
$table-color: $primary-color,
  $secondary-color,
  $success-color,
  $danger-color,
  $info-color,
  $tertiary-color,
  $light-color,
  $dark-color,
  $warning-color;

@each $var in $table-name {
  $i: index($table-name, $var);

  .table-styling .table-#{$var},
  .table-styling.table-#{$var} {
    background-color: nth($border-color, $i);
    color: $white;
    border: 3px dashed nth($border-color, $i);

    thead {
      background-color: darken(nth($border-color, $i), 10%);
      border: 3px dashed darken(nth($border-color, $i), 10%);
    }

    th,
    td {
      background-color: transparent;
    }
  }
}


/*======= Table styling css ends  ======= */

/*======= Svg stroke css start  ======= */
$stroke-name: primary,
  secondary,
  success,
  danger,
  info,
  tertiary,
  light,
  dark,
  warning;
$stroke-color: $primary-color,
  $secondary-color,
  $success-color,
  $danger-color,
  $info-color,
  $tertiary-color,
  $light-color,
  $dark-color,
  $warning-color;

@each $var in $stroke-name {
  $i: index($stroke-name, $var);

  .stroke-#{$var} {
    stroke: nth($stroke-color, $i) !important;
  }
}

/*======= Svg stroke css end  ======= */

/*======= Checkbox color css start  ======= */
$checkbox-name: primary,
  secondary,
  success,
  danger,
  info,
  tertiary,
  light,
  dark,
  warning;
$checkbox-color: $primary-color,
  $secondary-color,
  $success-color,
  $danger-color,
  $info-color,
  $tertiary-color,
  $light-color,
  $dark-color,
  $warning-color;

@each $var in $checkbox-name {
  $i: index($stroke-name, $var);

  .checkbox-#{$var} {

    &.form-check-input {
      &:checked {
        background-color: nth($checkbox-color, $i);
        border-color: nth($checkbox-color, $i);

      }
    }

  }
}

/*======= Checkbox color css end  ======= */


/*======= All-Borders-color css starts  ======= */

$b-name: primary,
  secondary,
  success,
  danger,
  tertiary,
  info,
  light,
  dark,
  warning;
$b-color: $primary-color,
  $secondary-color,
  $success-color,
  $danger-color,
  $tertiary-color,
  $info-color,
  $light-color,
  $dark-color,
  $warning-color;

@each $var in $b-name {
  $i: index($b-name, $var);

  .b-#{$var} {
    border: 1px solid nth($b-color, $i) !important;
  }

  .b-light-#{$var} {
    border: 1px solid rgba(nth($b-color, $i), 0.3) !important;
  }

  .b-t-#{$var} {
    border-top: 1px solid nth($b-color, $i) !important;
  }

  .border-t-#{$var} {
    border-top: 1px solid nth($b-color, $i) !important;
  }

  .b-b-#{$var} {
    border-bottom: 1px solid nth($b-color, $i) !important;
  }

  .border-b-#{$var} {
    border-bottom: 1px solid nth($b-color, $i) !important;
  }

  .b-l-#{$var} {
    border-left: 1px solid nth($b-color, $i) !important;
  }

  .border-l-#{$var} {
    border-left: 1px solid nth($b-color, $i) !important;
  }

  .b-r-#{$var} {
    border-right: 1px solid nth($b-color, $i) !important;
  }

  .border-r-#{$var} {
    border-right: 1px solid nth($b-color, $i) !important;
  }
}


/*======= All-Borders-color css ends  ======= */


/*====== Border width css starts ======*/
$i: 1;

@while $i<=10 {
  .border-#{$i} {
    border-width:#{$i}px !important;
  }

  $i: $i+1;
}

/*====== Border width css ends ======*/

.opacity-0 {
  opacity: 0;
}

.map-block {
  height: 350px;
  width: 100%;
}

.map-js-height {
  height: 500px;

  .btn-group {
    input {
      background-color: $primary-color;
      color: $white;

      &+input {
        background-color: $secondary-color;
      }
    }
  }
}

/**====== custom scrollbar css start ======**/
.custom-scrollbar {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px $light-gray;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(68, 102, 242, 0.15);
  }
}

.theme-scrollbar,
.datatable-container,
.jsgrid-grid-body,
.jsgrid-grid-header,
.dataTables_scrollBody,
.anchor {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px $light-gray;
  }

  &::-webkit-scrollbar {
    width: 2px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(68, 102, 242, 0.15);
  }
}


/**====== Custom scrollbar css end ======**/

/**====== Animation css Start ======**/
.comment {
  color: #9f9ba5;
  font-style: italic;
  display: block;
}

.line {
  color: $dark-color;

  pre {
    font-size: 100%;
  }
}

/**====== Animation css end ======**/

/**====== list style css Start ======**/
.list-circle {
  list-style: circle;
}

/**====== list style css end ======**/

/**====== Modal style css Start ======**/
.theme-close {
  opacity: 1;
  height: 40px;
  width: 40px;
  position: absolute;
  font-weight: 400;
  z-index: 1;
  right: 0;
  background-color: $white  !important;
  border-radius: 5px;
}

/**====== Modal style css end ======**/

/**====== Animation css start ======**/
.options {
  >div {
    color: $gray-60;
    display: inline-block;
    padding: 2px 10px;
    border: 1px dashed;
    margin: 0 8px 8px 0;
    transition: all 0.3s ease;

    &:hover {
      background-color: $primary-color;
      color: $white;
      transition: all 0.3s ease;
    }
  }
}

/**====== Animation css Ends ======**/

.modal-footer {
  flex-wrap: wrap;
}

.img-cropper {
  #putData {
    margin-bottom: 0;
  }

  .img-container {
    min-height: auto;
    margin-bottom: 0;
  }

  .docs-data {
    >.input-group {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .docs-preview {
    margin-top: 20px;
    margin-bottom: 10px;
  }
}

.dropzone {
  .dz-preview {
    .dz-error-message {
      background: $white  !important;
      color: $primary-color  !important;
      border: 1px dashed $primary-color;

      &:after {
        border-bottom: 6px dashed $primary-color  !important;
      }
    }
  }
}

.typeahead {
  .theme-form {
    .form-group {
      margin-bottom: 0;
    }
  }
}

.editor-statusbar {
  border-bottom: 1px dashed rgba(0, 0, 0, 0.125);
}

.page-builder {
  .ge-canvas {
    &.ge-editing {
      .row {
        padding: 30px;
        margin-bottom: 0;
        background-color: rgba($primary-color, 0.02);
      }
    }
  }

  .btn-screen {
    padding: 0 18px 0 0;
  }
}

.bg-overlay {
  &.active {
    height: 100vh;
    width: 100vw;
    background-color: rgba($black, 0.2);
    position: fixed;
    z-index: 8;
    top: 0;
  }
}

button {
  &:focus {
    outline: none !important;
  }
}

.primary {
  .svg-fill {
    fill: var(--theme-deafult);

    &:not(.half-circle) {
      stroke: var(--theme-deafult);
    }
  }

  .bg-gradient {
    background-image: linear-gradient(144.16deg, rgba($primary-color, 0.1) 19.06%, rgba($primary-color, 0) 79.03%) !important;
  }
}

.secondary {
  .svg-fill {
    fill: var(--theme-secondary);

    &:not(.half-circle) {
      stroke: var(--theme-secondary);
    }
  }

  .bg-gradient {
    background-image: linear-gradient(144.16deg, rgba($secondary-color, 0.1) 19.06%, rgba($secondary-color, 0) 79.03%) !important;
  }
}

.success {
  .svg-fill {
    fill: $success-color;
  }

  .bg-gradient {
    background-image: linear-gradient(144.16deg, rgba($success-color, 0.1) 19.06%, rgba($success-color, 0) 79.03%) !important;
  }
}

.warning {
  .svg-fill {
    fill: $warning-color;
  }

  .bg-gradient {
    background-image: linear-gradient(144.16deg, rgba($warning-color, 0.1) 19.06%, rgba($warning-color, 0) 79.03%) !important;
  }
}


.dotted {
  border-style: dotted;
}

.dashed {
  border-style: dashed;
}

.double {
  border-style: double;
}

.groove {
  border-style: groove;
}

.ridge {
  border-style: ridge;
}

.inset {
  border-style: inset;
}

.outset {
  border-style: outset;
}

@keyframes fadeIncustom {
  0% {
    opacity: 0;
    top: 50px;
  }

  75% {
    opacity: .6;
    top: 0;
  }

  100% {
    opacity: 1;
  }
}

.code-box-copy {
  width: 100%;
  overflow: auto;
}

@media (max-width: 1660px) and (min-width: 1400px) {
  .custom-rs-4 {
    width: 33.33333333%;
  }

  .custom-rs-12 {
    width: 100%;
  }
}

//progress color

$colors: (
  primary: $primary-color,
  secondary: $secondary-color,
  tertiary: $tertiary-color,
  warning: $warning-color,
  info: $info-color,
  success: $success-color,
  danger: $danger-color,
  light: $light-color,
  dark: $dark-color
);

@each $key,
$val in $colors {
  .progress-striped-#{$key} {
    background-color: rgba($val, 0.1);

    .progress-bar {
      background-image: linear-gradient(120deg, rgba($val, 1) 25%, transparent 25%, transparent 50%, rgba($val, 1) 50%, rgba($val, 1) 75%, transparent 75%, transparent);
      background-size: 16px 30px;
      --bs-progress-bar-bg: rgba($val, 0.1);
      animation: progress-bar-stripes 5s linear infinite;
    }
  }

  .progress-border-#{$key} {
    &.progress-bar {
      position: relative;

      &:after {
        position: absolute;
        content: "";
        top: -2px;
        right: -9px;
        background-color: $val;
        width: 12px;
        height: 12px;
        border-radius: 100%;
      }
    }
  }

  .progress-border-#{$key} {
    &.progress {
      background-color: rgba($val, 0.15);
    }

    .progress-bar {
      position: relative;
      overflow: visible;
      background-color: $val;
      border-radius: 15px 0 0 15px;

      [dir="rtl"] & {
        border-radius: 0 15px 15px 0;
      }

      &:after {
        position: absolute;
        content: "";
        top: -4px;
        right: 0;
        background-color: $val;
        width: 1px;
        height: 16px;

        [dir="rtl"] & {
          left: 0px;
          right: unset;
        }
      }
    }

  }
}

@keyframes progress-bar-stripes {
  0% {
    background-position: 40px 0;
  }

  100% {
    background-position: 0 0;
  }
}

// dashboard-7
.apexcharts-canvas {
  .apexcharts-tooltip {
    .apexcharts-tooltip-marker {
      width: 10px;
      height: 10px;
    }

    .apexcharts-tooltip-goals-group,
    .apexcharts-tooltip-z-group {
      display: none;
    }
  }
}

// dashboard-8
.bg-gray {
  background-color: var(--text-gray);
}

.text-gray {
  color: var(--text-gray) !important;
}


// common css
.common-space {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Override the default tooltip arrow color */
.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  border-top-color: $light-primary;
}

.bs-tooltip-end .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  border-right-color: $light-primary;
}

.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  border-bottom-color: $light-primary;
}

.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  border-left-color: $light-primary;
}

@media screen and (max-width: 1399px) and (min-width: 1200px) {
  .proorder-xl-1 {
    order: 1;
  }

  .proorder-xl-2 {
    order: 2;
  }

  .proorder-xl-3 {
    order: 3;
  }

  .proorder-xl-4 {
    order: 4;
  }

  .proorder-xl-5 {
    order: 5;
  }

  .proorder-xl-6 {
    order: 6;
  }

  .proorder-xl-7 {
    order: 7;
  }

  .proorder-xl-8 {
    order: 8;
  }

  .proorder-xl-9 {
    order: 9;
  }

  .proorder-xl-10 {
    order: 10;
  }


  .proorder-xl-11 {
    order: 11;
  }

  .proorder-xl-12 {
    order: 12;
  }

  .proorder-xl-13 {
    order: 13;
  }
}

@media screen and (max-width: 1199px) and (min-width: 768px) {
  .proorder-md-1 {
    order: 1;
  }

  .proorder-md-2 {
    order: 2;
  }

  .proorder-md-3 {
    order: 3;
  }

  .proorder-md-4 {
    order: 4;
  }

  .proorder-md-5 {
    order: 5;
  }

  .proorder-md-6 {
    order: 6;
  }

  .proorder-md-7 {
    order: 7;
  }

  .proorder-md-8 {
    order: 8;
  }

  .proorder-md-9 {
    order: 9;
  }

  .proorder-md-10 {
    order: 10;
  }


  .proorder-md-11 {
    order: 11;
  }

  .proorder-md-12 {
    order: 12;
  }

  .proorder-md-13 {
    order: 13;
  }
}

@media (max-width: 1500px) and (min-width: 1200px) {
  .col-xl-100 {
    flex: 0 0 auto;
    width: 100%;
  }

  .col-xl-50 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-40 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-33 {
    flex: 0 0 auto;
    width: 33.333333%;
  }

  .col-xl-70 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
}

@media screen and (max-width: 1399px) and (min-width: 1199px) {
  .proorder-4-xl-1 {
    order: 1;
  }
}

/**=====================
    1.1 Reset CSS Ends
==========================**/