/**=====================
    5.8 Rtl CSS Start
==========================**/
html[dir="rtl"] {
  ul {
    padding-right: 0;
  }

  .ms-auto {
    margin-left: unset !important;
    margin-right: auto !important;
  }

  .user-profile {
    .hovercard {
      .user-image {
        .icon-wrapper {
          left: -30%;
        }
      }
    }
  }
  .prooduct-details-box {
    .d-flex{
      img {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }
  .contacts-tabs {
    .nav-pills {
      border-left: 1px dashed rgba($black, 0.1);
      border-right: none;
    }
  }
  .bookmark-tabcontent {
    .details-bookmark {
      .bookmark-card.card {
        .title-bookmark {
          .hover-block {
            text-align: left;
            right: 0;
            ul {
              li {
                &.pull-right {
                  float: unset !important;
                }
              }
            }
          }
        }
      }
    }
  }
  .slider-wrapper {
    direction: ltr;
  }

  .fg-emoji-container {
    left: 107px !important;
  }

  .f-right {
    float: left;
  }

  .papernote-wrap {
    .note-labels {
      ul {
        gap: 9px;
      }
    }
  }

  .form-check-inline {
    margin-right: unset;
    margin-left: 1rem;
  }

  .color-box {
    button {
      +button {
        margin-left: unset;
        margin-right: 5px;
      }
    }
  }

  .knowledgebase-search {
    .form-inline {
      svg {
        left: unset;
        right: 20px;
      }
    }
  }

  .switch-showcase {
    .form-check {
      .form-check-input {
        float: left;
      }
    }
  }
  .msger-inputarea {
    .dropdown-form {
      .chat-icon{
        right: 0 !important;
        left: 0 !important;
      }
    }

  }
  .pill-input-group span {
    &:last-child {
      border-top-right-radius: unset !important;
      border-bottom-right-radius: unset !important;
      border-top-left-radius: 1.3rem !important;
      border-bottom-left-radius: 1.3rem !important;
    }

    &:first-child {
      border-top-left-radius: unset !important;
      border-top-right-radius: unset !important;
      border-top-right-radius: 1.3rem !important;
      border-bottom-right-radius: 1.3rem !important;
    }
  }

  .page-wrapper {
    &.compact-wrapper {
      .page-body-wrapper {
        .sidebar-wrapper {
          text-align: right;

          .toggle-sidebar {
            right: unset;
            left: 30px;
          }

          .sidebar-main .sidebar-links {
            padding: 0;

            .simplebar-wrapper {
              .simplebar-mask {
                .simplebar-content-wrapper {
                  .simplebar-content {
                    >li .badge {
                      left: 50px !important;
                      right: unset !important;
                    }
                  }
                }
              }
            }
          }

          &.close_icon {
            margin-right: 0;
            margin-left: unset;

            .logo-icon-wrapper {
              text-align: left;

              img {
                margin-left: 8px;
              }
            }

            ~.page-body,
            ~footer {
              margin-left: 0;
              margin-right: 88px;
              transition: 0.5s;
            }

            .sidebar-main {
              .sidebar-links {
                .simplebar-wrapper {
                  .simplebar-mask {
                    .simplebar-content-wrapper {
                      .simplebar-content {
                        >li {
                          a {
                            svg {
                              margin-left: 0;
                            }
                          }
                        }
                      }
                    }
                  }
                }

                .sidebar-list {
                  .sidebar-title {
                    &.active {
                      ~.sidebar-submenu {
                        display: none !important;
                      }
                    }
                  }
                }
              }
            }

            .according-menu {
              display: none;
            }

            &:hover {
              .sidebar-main {
                .sidebar-links {
                  .simplebar-wrapper {
                    .simplebar-mask {
                      .simplebar-content-wrapper {
                        .simplebar-content {
                          >li {
                            a {
                              svg {
                                margin-left: 13px;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          &:hover {
            transform: translate(0px);

            .sidebar-main {
              .sidebar-links {
                .sidebar-list {
                  .sidebar-title {
                    &.active {
                      ~.sidebar-submenu {
                        display: block !important;
                      }
                    }
                  }
                }

                .simplebar-wrapper {
                  .simplebar-mask {
                    .simplebar-content-wrapper {
                      .simplebar-content {
                        >li {
                          a {
                            svg {
                              float: none;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            .according-menu {
              display: block;
            }
          }

          .sidebar-main {
            .sidebar-links {
              .simplebar-wrapper {
                .simplebar-mask {
                  .simplebar-content-wrapper {
                    .simplebar-content {
                      >li {
                        .sidebar-submenu {
                          &:after {
                            right: 30px;
                            left: unset;
                          }

                          >li {
                            a {
                              padding-right: 62px !important;
                              padding-left: unset !important;

                              &:after {
                                right: 28px;
                                left: unset;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .page-body {
          margin-left: unset;
          margin-right: 265px;
        }

        .sidebar-wrapper {
          .sidebar-main {
            .sidebar-links {
              .simplebar-wrapper {
                .simplebar-mask {
                  .simplebar-content-wrapper {
                    .simplebar-content {
                      >li {
                        a {
                          svg {
                            margin-right: unset;
                            margin-left: 15px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .according-menu {
          right: unset;
          left: 20px;
        }
      }
    }

    &.only-body {
      .page-header {
        .header-wrapper {
          .nav-right {
            right: unset;
            left: 30px;
          }
        }
      }
    }
  }

  // task page css
  .date-details {
    >div {
      +div {
        margin-left: unset;
        margin-right: 10px;
      }
    }
  }

  // contact page css
  .contact-editform {
    &.ps-0 {
      padding-left: 20px !important;
    }
  }

  .contact-options {
    li {
      .btn-category {
        padding-left: unset !important;
        padding-right: 40px;
      }
    }
  }

  .list-persons {
    .profile-mail {
      .email-general {
        ul {
          padding-right: unset;
          padding-left: 20px;

          li {
            >span {
              float: left;
            }
          }
        }
      }

      .d-flex {
        .flex-grow-1 {
          ul {
            li {
              +li {
                padding-left: unset;
                padding-right: 10px;
                margin-left: unset;
                margin-right: 5px;
                border-left: unset;
                border-right: 1px solid $light-color;
              }
            }
          }
        }
      }
    }
  }

  // file manager css
  .quick-file {
    li {
      margin-left: 20px;
      margin-right: unset;
      margin-bottom: 10px;
    }
  }

  .file-manager {
    .folder {
      margin-bottom: -12px;

      li {
        &:nth-child(n+2) {
          margin-right: 0px;
          margin-left: unset;
          margin-bottom: 12px;
        }
      }
    }
  }

  .files-content {
    li {
      margin-left: 20px;
      margin-right: unset;
      margin-bottom: 10px;
    }
  }

  .file-content {
    .folder {
      .folder-box {
        .ellips {
          right: unset;
          left: 22px;
        }
      }
    }
  }

  .file-sidebar {
    .pricing-plan {
      .bg-img {
        transform: rotate(45deg);
        left: -40px;
        right: unset;
      }
    }
  }


  .file-content {
    .form-inline {
      i {
        padding-right: unset;
        padding-left: 10px;
      }
    }
  }

  .file-sidebar {
    .btn {
      svg {
        margin-right: unset;
        margin-left: 8px;
      }
    }
  }

  // projects page css
  .form-select {
    background-position: left 0.75rem center;
  }

  .customers {
    ul {
      li {
        +li {
          margin-left: unset;
          margin-right: -10%;
        }
      }
    }
  }

  .project-list {
    .border-tab {
      &.nav-tabs {
        .nav-item {
          .nav-link {
            padding: 5px 0 5px 10px;
          }
        }
      }
    }
  }

  .project-box {
    .badge {
      right: unset;
      left: 30px;
    }
  }

  // kanban board css
  .kanban-board {
    float: right;
  }

  .kanban-item {
    .kanban-box {
      .customers {
        ul {
          li {
            +li {
              margin-left: unset;
              margin-right: -10px;
            }
          }
        }
      }
    }

    .list {
      li {
        i {
          margin-right: unset;
          margin-left: 10px;
        }
      }
    }
  }

  //horizontal-sidebar
  .page-wrapper {
    &.horizontal-wrapper {
      .header-logo-wrapper {
        text-align: right;
      }

      .page-body-wrapper {
        .page-body {
          margin-right: 0;
        }

        footer {
          margin-right: 0;
        }
      }
    }

    &.material-type {
      .page-body-wrapper {
        .page-body {
          margin-right: auto;
        }
      }
    }
  }

  .page-header {
    .header-wrapper {
      .nav-right {
        >ul {
          >li {
            &:first-child {
              margin-right: 0;
            }
          }
        }

        &.right-header {
          ul {
            li {
              .profile-media {
                .flex-grow-1 {
                  margin-right: 15px;
                  margin-left: unset;
                }
              }
            }
          }
        }
      }

      .toggle-sidebar {
        margin-left: 2px;
        margin-right: unset;
      }
    }
  }

  // compact icon css
  .page-wrapper {
    .page-header {
      .header-wrapper {
        .nav-right {
          .notification-dropdown {
            left: -20px !important;
            right: unset !important;

            @media (max-width: 575.98px) {
              left: 12px !important;
            }
          }
        }

        .search-full {
          input {
            padding-left: unset;
            padding-right: 42px;
            text-align: right;
          }

          .form-group {
            &:before {
              left: unset;
              right: 30px;
            }

            .close-search {
              right: unset;
              left: 14px;
            }
          }
        }
      }
    }

    &.compact-sidebar {
      .toggle-sidebar {
        margin-left: 15px;
        margin-right: unset;
      }

      .page-header {
        margin-left: unset;
        margin-right: 150px;

        &.close_icon {
          margin-right: 0;
        }
      }

      .page-body-wrapper {
        .page-body {
          margin-left: unset;
          margin-right: 150px;
        }

        .footer {
          margin-left: unset;
          margin-right: 150px;
        }

        div.sidebar-wrapper {
          >div {
            text-align: right;
          }

          .sidebar-main {
            .sidebar-links {
              li {
                a {
                  &.active {

                    ~.sidebar-submenu,
                    ~.mega-menu-container {
                      animation: fadeInRight 1000ms ease-in-out;
                    }
                  }
                }

                .sidebar-submenu,
                .mega-menu-container {
                  right: 150px;
                  left: unset;

                  li {
                    a {
                      padding: 15px 40px 15px 15px;

                      .according-menu {
                        right: 20px;
                      }
                    }
                  }

                  >li {
                    >a {
                      &::after {
                        left: unset;
                        right: 20px;
                      }
                    }
                  }

                  .mega-box {
                    .link-section {
                      text-align: right;

                      .submenu-title {
                        h5 {
                          padding: 15px 40px 15px 15px;

                          &::after {
                            left: unset;
                            right: 20px;
                          }
                        }
                      }
                    }
                  }
                }
              }

              .sidebar-list {
                .badge {
                  left: unset;
                  right: 53%;
                }

                .sidebar-link {
                  text-align: center;
                }
              }

              .mega-menu {
                text-align: center;

                .according-menu {
                  right: unset;
                  left: 20px;
                }
              }
            }
          }

          &.close_icon {
            transform: translateX(150px);

            ~.page-body,
            ~.footer {
              margin-right: 0px;
            }
          }
        }
      }
    }

    &.compact-small {
      .page-header {
        margin-right: 90px;
      }

      .page-body-wrapper {
        div.sidebar-wrapper {
          .sidebar-main {
            .sidebar-links {
              li {

                .sidebar-submenu,
                .mega-menu-container {
                  right: 90px;
                }
              }
            }
          }
        }

        .footer,
        .page-body {
          margin-right: 90px;
        }
      }
    }

    &.material-icon {
      .page-header {
        padding-left: unset;
        padding-right: 90px;
        margin: 0 auto;

        &.close_icon {
          padding-right: 0;
        }
      }

      .page-body-wrapper {
        .page-body {
          padding-left: 15px;
          padding-right: 90px;
          margin: 0 auto;
        }

        div.sidebar-wrapper {
          &.close_icon {
            ~.page-body {
              padding-right: 15px;
              margin: 0 auto !important;
            }
          }
        }
      }
    }
  }

  .apexcharts-canvas {
    direction: ltr;
  }

  // bookmark app css
  .email-wrap {
    .btn-mail {
      text-align: right;
    }

    .email-app-sidebar {
      ul {
        li {
          a {
            padding-left: unset;
            padding-right: 20px;
          }
        }
      }
    }
  }

  .bookmark-tabcontent {
    .details-bookmark {
      .bookmark-card {
        &.card {
          .favourite-icon {
            text-align: center;
          }

          .title-bookmark {
            text-align: right;
          }
        }
      }
    }
  }

  .bookmark-wrap {
    .details-bookmark {
      .row {
        >div {
          +div {
            padding-left: 15px !important;
          }
        }
      }
    }
  }

  // index page css start
  .appointment-table {
    table {
      tr {
        td {
          .status-circle {
            left: unset;
            right: 35px;
          }
        }
      }
    }
  }

  .notification {
    .card {
      .d-flex {
        .w-100 {
          p {
            .badge {
              margin-left: unset;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }

  // index page css end
  @each $progress-gradient-name,
  $progress-gradient-color in (primary, var(--theme-deafult)),
  (secondary, $secondary-color),
  (success, $success-color),
  (danger, $danger-color),
  (info, $info-color),
  (light, $light-color),
  (dark, $dark-color),
  (warning, $warning-color) {
    .progress-gradient-#{$progress-gradient-name} {
      background-image: linear-gradient(to right, $progress-gradient-color, $transparent-color);

      &::after {
        right: unset;
        left: -1px;
      }

      .animate-circle {
        right: unset;
        left: -8px;
      }
    }
  }

  .progress-animate {
    span {
      left: -10px;
      right: unset;
    }
  }

  // ecommerce dashboard css end
  .me-4 {
    margin-left: 1.5rem !important;
    margin-right: unset !important;
  }

  .pe-4 {
    padding-left: 1.5rem !important;
    padding-right: unset !important;
  }

  .me-1 {
    margin-left: 0.25rem !important;
    margin-right: unset !important;
  }

  .navs-dropdown {
    .onhover-show-div {
      right: 0;
    }
  }

  .default-according {
    &.style-1 {
      button {
        text-align: right;

        &:before {
          left: 20px;
          right: unset;

          @media (max-width: 575px) {
            left: 15px;
          }
        }

        i {
          right: 18px;
          left: unset;
        }
      }
    }

    .card {
      .card-header {
        i {
          right: 18px;
          left: unset;
        }
      }
    }
  }

  .faq-wrap {
    .faq-accordion {
      .card {
        .card-header {
          .btn-link {
            padding-right: 20px !important;

            @media (max-width: 575.98px) {
              padding-right: 10px !important;
            }
          }
        }
      }
    }
  }

  .ProfileCard-details {
    padding-right: 30px;
    padding-left: unset;
    float: right;
  }

  .starter-main {
    .card-body {
      ul {
        padding-right: 30px;
        padding-left: unset;
      }
    }
  }

  .theme-tab {
    .tab-title {
      li {
        a {
          svg {
            margin-left: 5px;
            margin-right: unset;
          }
        }
      }
    }
  }

  .spent {
    .spent-graph {
      .project-budget {
        margin-left: 30px;
        margin-right: unset;
      }
    }
  }

  .support-table {
    table {
      tbody {
        tr {
          td {
            &:first-child {
              padding-right: 0;
              padding-left: unset;
            }
          }
        }
      }
    }
  }

  .order-box {

    .sub-total,
    .total,
    .qty {

      .shipping-class,
      li {

        .shopping-checkout-option,
        span {
          float: left;
        }
      }

      li {
        .count {
          float: left;
        }
      }
    }
  }

  .img-paypal {
    margin-right: 15px;
    margin-left: unset;
  }

  .cart {
    .qty-box {
      .input-group {
        .btn {
          border-radius: 0 !important;
        }
      }
    }
  }

  .product-qnty {
    fieldset {
      .input-group {
        .input-group-prepend {
          .btn {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
      }
    }
  }

  .grid-options {
    margin-right: 10px;
    margin-left: unset;

    ul {
      li {
        a {
          .line-grid-1 {
            right: 12px;
            left: unset;
          }

          .line-grid-2 {
            right: 18px;
            left: unset;
          }

          .line-grid-3 {
            right: 36px;
            left: unset;
          }

          .line-grid-4 {
            right: 42px;
            left: unset;
          }

          .line-grid-5 {
            right: 48px;
            left: unset;
          }

          .line-grid-6 {
            right: 66px;
            left: unset;
          }

          .line-grid-7 {
            right: 72px;
            left: unset;
          }

          .line-grid-8 {
            right: 78px;
            left: unset;
          }

          .line-grid-9 {
            right: 84px;
            left: unset;
          }

          .line-grid-10 {
            right: 103px;
            left: unset;
          }

          .line-grid-11 {
            right: 109px;
            left: unset;
          }

          .line-grid-12 {
            right: 115px;
            left: unset;
          }

          .line-grid-13 {
            right: 121px;
            left: unset;
          }

          .line-grid-14 {
            right: 127px;
            left: unset;
          }

          .line-grid-15 {
            right: 133px;
            left: unset;
          }
        }
      }
    }
  }

  .product-filter {
    &.new-products {
      .owl-theme {
        .owl-nav {
          left: 0;
          right: unset;
        }

        .owl-item {
          .item {
            .product-box {
              .product-details {
                &.text-start {
                  text-align: left !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .product-sidebar {
    .filter-section {
      .card {
        .card-header {
          h6 {
            .pull-right {
              i {
                left: auto;
                right: unset;
              }
            }
          }
        }
      }
    }
  }

  .prooduct-details-box {
    .btn {
      right: unset;
      left: 15px;
    }

    .close {
      right: unset;
      left: 10px;
    }
  }

  .collection-filter-block {
    svg {
      margin-right: unset;
      margin-left: 10px;
    }
  }

  .feature-products {
    form {
      .form-group {
        i {
          left: 30px;
          right: unset;
        }
      }
    }
  }

  .product-wrapper-grid {
    &.list-view {
      .product-box {
        .product-details {
          text-align: right;
        }
      }
    }
  }

  .page-wrapper {
    .page-body-wrapper {
      .page-title {
        .breadcrumb {
          margin-left: unset;
        }
      }
    }
  }

  .bookmark {
    ul {
      li {
        +li {
          margin-left: unset;
          margin-right: 10px;
        }

        .search-form {
          .form-control-search {
            left: -50px;
            right: unset;

            &:before {
              right: 50px;
              left: unset;
            }

            &:after {
              right: 20px;
              left: unset;
            }
          }
        }
      }
    }
  }

  .me-0 {
    margin-left: 0 !important;
    margin-right: unset !important;
  }

  .footer {
    .pull-right {
      float: none;
    }
  }

  .checkbox_animated,
  .radio_animated {
    margin: 0 8px 0 16px;
  }

  .button.remove {
    left: -10px;
    right: unset;
  }

  .code-box-copy__btn {
    left: 11px;
    right: unset;
  }

  ul {
    &.nav-menus {
      border-bottom: none;
    }

    &.notification-dropdown {
      &.onhover-show-div {
        li {
          text-align: right;
        }
      }
    }
  }

  .badge {
    +.badge {
      margin-right: 5px;
      margin-left: unset;
    }
  }

  .offset-xl-3 {
    margin-right: 25%;
    margin-left: unset;
  }

  .m-l-10 {
    margin-right: 10px !important;
    margin-left: unset !important;
  }

  .m-r-20 {
    margin-left: 20px;
    margin-right: unset;
  }

  .m-r-30 {
    margin-left: 30px;
    margin-right: unset;
  }

  .m-r-5 {
    margin-left: 5px;
    margin-right: unset;
  }

  .text-start {
    text-align: right !important;
  }

  .m-r-10 {
    margin-left: 10px;
    margin-right: unset;
  }

  .pe-3 {
    padding-left: 1rem !important;
    padding-right: unset !important;
  }

  .p-r-0 {
    padding-left: 0;
    padding-right: 15px;
  }

  .m-l-20 {
    margin-right: 20px !important;
    margin-left: unset !important;
  }

  .ps-3 {
    padding-right: 1rem !important;
  }

  .b-r-light {
    border-left: 1px dashed $light-color  !important;
    border-right: unset !important;
  }

  .float-end {
    float: left !important;
  }

  .float-start {
    float: right !important;
  }

  .text-end {
    text-align: left !important;
  }

  .border-right {
    border-left: 1px dashed #dee2e6 !important;
    border-right: unset !important;
  }

  .pe-0 {
    padding-left: 0 !important;
    padding-right: 12px !important;
  }

  .ps-0 {
    padding-right: 0 !important;
    padding-left: unset !important;
  }

  .ms-1 {
    margin-left: unset !important;
    margin-right: 0.25rem !important;
  }

  .owl-carousel {
    direction: ltr;
  }

  .btn-group {
    > {

      .btn:not(:last-child):not(.dropdown-toggle),
      :not(:last-child)>.btn {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
      }

      .btn:not(:first-child) {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      .btn:nth-child(2) {
        border-radius: 0 !important;
      }
    }

    .btn-group> :not(:first-child)>.btn {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .btn+ {

      .btn,
      .btn-group {
        margin-right: -1px;
        border-right: none;
      }
    }

    .btn-group+ {

      .btn,
      .btn-group {
        margin-right: -1px;
      }
    }

    .btn-group-vertical {
      .btn+ {

        .btn,
        .btn-group {
          margin-right: -1px;
        }
      }

      .btn-group+ {

        .btn,
        .btn-group {
          margin-right: -1px;
        }
      }
    }
  }

  .me-3 {
    margin-right: unset !important;
    margin-left: 1rem !important;
  }

  .me-5 {
    margin-left: 3rem !important;
    margin-right: unset !important;
  }

  .ms-3 {
    margin-right: 1rem !important;
    margin-left: unset !important;
  }

  .m-r-15 {
    margin-left: 15px;
    margin-right: unset;
  }

  .b-l-light {
    border-right: 1px dashed $light-color  !important;
    border-left: unset !important;
  }

  .p-l-0 {
    padding-left: unset;
    padding-right: 0px;
  }

  .ps-4 {
    padding-right: 1.5rem !important;
    padding-left: unset !important;
  }

  .me-2 {
    margin-left: 0.5rem !important;
    margin-right: unset !important;
  }

  .ms-2 {
    margin-right: 0.5rem !important;
    margin-left: unset !important;
  }

  .pe-2 {
    padding-right: unset !important;
    padding-left: 0.5rem !important;
  }

  .prooduct-details-box {
    .flex-grow-1 {
      margin-right: 0 !important;
    }
  }

  .alert {
    text-align: right;
  }

  .pull-right {
    float: left;
  }

  .form-inline {
    .form-group {
      margin-left: 15px;
      margin-right: 0;
    }
  }

  .card {
    text-align: right;

    &.social-profile {
      text-align: center;
    }

    .card-header {
      h5:not(.mb-0) {
        float: right;
      }

      span {
        clear: both;
      }

      .card-header-right {
        left: 26px;
        right: unset;

        .card-option {
          text-align: left;

          &.list-unstyled {
            padding-right: 0;
            padding-left: unset;
          }
        }
      }

      .pull-left {
        float: right;
      }
    }

    &.full-card {
      left: 0;
      right: unset;
    }

    .card-body {
      .alert {
        svg {
          margin-left: 4px;
          margin-right: unset;
        }
      }

      .value-left {
        margin-left: 30px;
        margin-right: unset;
      }

      .square {
        margin-left: 5px;
        margin-right: unset;
      }
    }
  }

  .explore-card {
    text-align: left;

    .group-btn {
      justify-content: flex-end;
    }
  }

  .balance-box {
    text-align: center;
  }

  .purchase-card {
    text-align: center;
  }

  .modal-header {
    .btn-close {
      margin: -0.5rem auto -0.5rem -0.5rem;
    }
  }

  .modal-footer {
    > :not(:first-child) {
      margin-right: .50rem;
      margin-left: 0;
    }
  }

  .form-inline {
    .form-group {
      .col-form-label {
        margin-left: 5px;
        margin-right: 0;
      }
    }
  }

  .breadcrumb-item {
    &+.breadcrumb-item {
      padding-right: 0.4rem;
      padding-left: unset;

      &::before {
        padding-left: 0.4rem;
        padding-right: unset;
        float: right;
      }
    }
  }

  code[class*=language-],
  pre[class*=language-] {
    text-align: right;
  }

  .input-group-prepend {
    margin-left: -1px;
    margin-right: unset;
  }

  .input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
  .input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n + 3) {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: unset;
    margin-right: -1px;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .was-validated .form-control:invalid,
  .form-control.is-invalid,
  .was-validated .form-control:valid,
  .form-control.is-valid {
    background-position: left calc(0.375em + 0.1875rem) center;
    padding-left: calc(1.5em + 0.75rem);
    padding-right: 12px;
  }

  .input-group>.form-control:not(:last-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .input-group>.input-group-prepend>.btn,
  .input-group>.input-group-prepend>.input-group-text,
  .input-group>.input-group-append:not(:last-child)>.btn,
  .input-group>.input-group-append:not(:last-child)>.input-group-text,
  .input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
  .input-group>.input-group-append:last-child>.input-group-text:not(:last-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  .input-group>.input-group-append>.btn,
  .input-group>.input-group-append>.input-group-text,
  .input-group>.input-group-prepend:not(:first-child)>.btn,
  .input-group>.input-group-prepend:not(:first-child)>.input-group-text,
  .input-group>.input-group-prepend:first-child>.btn:not(:first-child),
  .input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .border-tab.nav-left .nav-link {
    text-align: left;
  }

  // social app page css
  .socialprofile {
    .social-group {
      ul {
        li {
          &:nth-child(4) {
            margin-right: -22px;
          }
        }
      }
    }
  }

  .social-status {
    form {
      .form-group {
        .form-control-social {
          padding-right: 15px;
          padding-left: unset;
        }
      }
    }

    .d-flex {
      .social-status {
        right: 35px;
        left: unset;
      }
    }
  }

  .social-chat {
    .other-msg {
      margin-right: 40px;
      margin-left: unset;
    }

    .flex-grow-1 {
      &:before {
        left: 100%;
        right: unset;
        border-left: 7px dashed rgba($black, 0.4);
        border-right: unset;
      }

      &:after {
        left: 100%;
        right: unset;
        border-left: 7px dashed $white;
        border-right: unset;
      }
    }
  }

  .timeline-content {
    .comment-number {
      i {
        margin-left: 20px;
        margin-right: unset;
      }
    }
  }

  .photos {
    ul {
      li {
        margin-right: unset;
        margin-left: 10px;
      }
    }
  }

  .avatar-showcase {
    .friend-pic {
      margin-left: 8px;
      margin-right: unset;
    }

    .pepole-knows {
      ul {
        li {
          margin-left: 21px;
          margin-right: unset;

          &:last-child {
            margin-left: 0;
          }
        }
      }
    }
  }

  .user-profile {
    .hovercard {
      .user-image {
        .share-icons {
          left: 100px;
          right: unset;

          li {
            margin-left: 10px;
            margin-right: unset;

            &:last-child {
              margin-left: 0;
            }
          }
        }
      }
    }
  }

  // faq page css
  .faq-form {
    .search-icon {
      left: 12px;
      right: unset;
    }
  }

  .faq-widgets {
    .flex-grow-1 {
      p {
        padding-left: 20px;
        padding-right: unset;
      }
    }
  }

  .header-faq,
  .faq-title {
    text-align: right;
  }

  .faq-accordion.default-according {
    .card {
      .faq-header {
        svg {
          left: 30px;
          right: unset;
        }
      }

      .btn-link {
        svg {
          margin-left: 10px;
          margin-right: unset;
        }
      }
    }
  }

  .navigation-option {
    ul {
      li {
        padding: 8px 0 8px 8px;

        a {
          padding-right: 40px;
          padding-left: unset;

          svg {
            right: 15px;
            left: unset;
          }
        }
      }
    }
  }

  .updates-faq {
    margin-right: unset;
    margin-left: 20px;
  }

  // knowledgebase page css
  .browse {
    .browse-articles {
      span {
        svg {
          margin-left: 10px;
          margin-right: unset;
        }
      }
    }
  }

  // learning app page css
  .categories {
    .learning-header {
      padding-right: 15px;
      padding-left: unset;
    }
  }

  // internationalization page css
  .main {
    .langChoice {
      left: 30px;
      right: unset;
    }
  }

  .pricing-block {
    svg {
      direction: ltr;
    }
  }

  // chart peity css
  p {
    &.data-attributes {
      svg {
        margin-left: 10px;
        margin-right: unset;
      }
    }
  }

  // avatars page css
  .avatars {
    .avatar {
      margin-left: 10px;
      margin-right: unset;

      &:last-child {
        margin-left: 0;
      }

      .status {
        left: 4px;
        right: unset;
      }
    }
  }

  .customers {
    &.avatar-group {
      margin-left: 30px;
      margin-right: unset;
    }
  }

  .card {
    .card-header {
      h5 {
        float: none !important;
      }
    }
  }

  .card-header {
    .header-top {
      .dropdown {
        .dropdown-menu {
          transform: translate(142px, 23px) !important;
        }
      }
    }
  }

  // knob chart css
  .knob-chart {
    .chart-clock-main {
      .clock-small {
        right: 10px;
        left: unset;
      }
    }
  }

  // to-do css
  .todo {
    .todo-list-wrapper {
      .mark-all-tasks {
        .btn-label {
          margin-left: 5px;
          margin-right: unset;
        }

        left: 30px;
        right: unset;
      }

      #todo-list {
        li {
          .task-container {
            .task-action-btn {
              text-align: left;
            }
          }
        }
      }
    }

    .notification-popup {
      left: 10px;
      right: unset;
    }
  }

  .todo-options {
    .badges-todo {
      h6 {
        margin-left: 10px;
        margin-right: unset;

        &:last-child {
          margin-left: 0;
        }
      }
    }
  }

  .todo-list-btns {
    .dropdown-basic {
      .btn-group {
        .form-group {
          .checkbox {
            padding-right: 14px;
            border-top-right-radius: 25px;
            border-bottom-right-radius: 25px;
            padding-left: unset;
            border-top-left-radius: unset;
            border-bottom-left-radius: unset;
          }
        }
      }

      .separated-btn {
        margin-right: -6px;
        margin-left: unset;

        .btn {
          border-top-left-radius: 25px;
          border-bottom-left-radius: 25px;
        }
      }
    }
  }

  .search-todo {
    .dropdown-basic {
      .btn-group {
        margin-left: 18px;
        margin-right: unset;
      }
    }
  }

  //sticky note css
  .sticky-note {
    .note {
      float: right;
    }
  }

  // mega-menu css
  .mega-menu {
    .list-unstyled {
      div {
        a {
          padding: 8px 0 8px 35px;

          &:hover {
            padding: 8px 10px 8px 35px;
          }
        }
      }
    }
  }

  #testimonial {
    .owl-stage-outer {
      .owl-stage {
        .owl-item {
          .slide--item {
            .d-flex {
              .me-3 {
                margin-right: 1rem !important;
                margin-left: unset !important;
              }
            }
          }
        }
      }
    }
  }

  .needs-validation,
  .form-row {

    .input-group>.form-control:not(:last-child),
    .input-group>.custom-select:not(:last-child) {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .form-check {
    padding-right: 1.25rem;
    padding-left: unset;

    .form-check-input {
      float: right;
      margin-left: unset;
      margin-right: -1.5em;
    }
  }

  .form-check-reverse {
    .form-check-input {
      float: right;
      margin-left: 0;
      margin-right: 10px;
    }
  }

  .mega-menu {
    .default-according {
      .card {
        .btn-link {
          text-align: right;
        }
      }

      &.style-1 {

        button[aria-expanded="true"],
        button[aria-expanded="false"] {
          &:before {
            right: unset;
            left: 20px;
          }
        }
      }
    }
  }

  // email css
  .email-wrap {
    .row {
      .col-xl-6 {
        padding-right: 0;
        padding-left: 15px;
      }

      .col-xl-3 {
        +.col-xl-3 {
          padding-left: 0;
          padding-right: 15px;
        }
      }
    }

    .email-app-sidebar {
      .main-menu {
        &>li {
          text-align: right;

          a {
            i {
              margin-right: unset;
              margin-left: 10px;
            }
          }
        }
      }

      ul {
        padding-right: 0;
      }
    }

    .email-content {
      .email-top {
        .user-emailid {
          &:after {
            float: left;
          }
        }
      }
    }

    .email-right-aside {
      .email-body {
        .pe-0 {
          padding-right: unset !important;
        }
      }

      .radius-left {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border-radius: 4px;
      }
    }
  }

  // calender css
  #renderRange {
    float: left;
    padding-left: unset;
    padding-right: 12px;
  }

  .event-calendar {
    direction: ltr;
  }

  .calendar-wrap {
    .fc {
      direction: rtl;
      text-align: right;

      .fc-toolbar {
        .fc-left {
          float: right;
        }

        .fc-right {
          float: left;
        }

        &>* {
          &>* {
            margin-left: 0;
          }
        }
      }
    }

    .fc-events-container {
      text-align: right;
    }

    .fc-scroller {
      margin-right: unset;
      margin-left: -17px;
    }
  }

  .fc-agenda-view {
    .fc-day-grid {
      .fc-row {
        margin-right: 0 !important;
      }
    }
  }

  .fc-unthemed {
    .fc-row {
      margin-right: 0 !important;
    }
  }

  // buttons css
  .btn-group-showcase {
    .btn-group {
      margin-left: 20px;
      margin-right: unset;
    }
  }

  .btn-showcase {
    .btn {
      margin-right: unset;
      margin-left: 10px;
    }
  }

  .btn-square {
    &.dropdown-toggle {
      border-top-left-radius: 0.25rem !important;
      border-bottom-left-radius: 0.25rem !important;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }

  // button-builder css
  &.button-builder {
    ul.the-icons {
      li {
        float: right;
      }
    }

    .color-slelector {
      float: right;
    }

    .custom-button-color {
      .form-control {
        border-radius: 0 5px 5px 0;

        &.pull-left {
          float: right;
        }
      }
    }

    .hint-label {
      float: right;
      padding: 4px 020px 0 0;
    }
  }

  #customer-review {
    .owl-item {
      img {
        order: 2;
      }
    }
  }

  .options {
    >div {
      margin: 0 0 8px 8px;
    }
  }

  // modal page
  .modal-dialog {
    .modal-content {
      .modal-body {
        p {
          a {
            margin-left: 0;
          }
        }
      }
    }
  }

  // forms page
  .drag-box {
    fieldset {
      .component {
        .form-group {
          .text-lg-left {
            text-align: right !important;
          }

          .input-group {
            .input-group-prepend {

              .btn,
              .input-group-text {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-top-right-radius: 0.25rem;
                border-bottom-right-radius: 0.25rem;
              }

              .btn-right,
              .checkbox-radius {
                border-top-left-radius: 0.25rem;
                border-bottom-left-radius: 0.25rem;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
              }
            }

            #prependedcheckbox {
              border-top-right-radius: 0 !important;
              border-bottom-right-radius: 0 !important;
            }

            #appendedcheckbox {
              border-right-color: $light-semi-gray;
            }

            #buttondropdown {
              margin-right: unset;
              margin-left: -1px;
            }
          }
        }
      }
    }
  }

  .drag {
    form {
      text-align: right;

      input {
        text-align: right;
      }

      label {
        text-align: right !important;
      }

      .form-group {
        .input-group {
          .input-group-prepend {

            .btn,
            .input-group-text {
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
              border-top-right-radius: 0.25rem;
              border-bottom-right-radius: 0.25rem;
            }

            .btn-right,
            .checkbox-radius {
              border-top-left-radius: 0.25rem;
              border-bottom-left-radius: 0.25rem;
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
            }
          }

          #buttondropdown {
            margin-right: unset;
            margin-left: -1px;
          }
        }
      }
    }
  }

  .draggable {
    label {
      text-align: right;
    }
  }

  .form-builder-column {
    .tab-content {
      .tab-pane {
        .theme-form {
          .ui-draggable {
            .input-group {
              >.input-group-prepend {
                >.input-group-text {
                  border-top-left-radius: 0;
                  border-bottom-left-radius: 0;
                  border-top-right-radius: 0.25rem;
                  border-bottom-right-radius: 0.25rem;
                }
              }
            }
          }
        }
      }
    }

    #pills-tabContent {
      .theme-form {

        .form-group,
        .ui-draggable {
          .input-group {
            #buttondropdown {
              margin-right: unset;
              margin-left: 15px;
            }

            .input-group-btn {
              button {
                border-radius: 4px !important;
              }
            }
          }
        }
      }
    }
  }

  .form-horizontal {
    &.theme-form {
      fieldset {
        .tab-content {
          .tab-pane {
            .component {
              .form-group {
                .text-lg-left {
                  text-align: right !important;
                }

                .input-group {
                  .input-group-prepend {

                    .btn,
                    .input-group-text {
                      border-top-left-radius: 0;
                      border-bottom-left-radius: 0;
                      border-top-right-radius: 0.25rem;
                      border-bottom-right-radius: 0.25rem;
                    }

                    .btn-right,
                    .checkbox-radius {
                      border-top-left-radius: 0.25rem;
                      border-bottom-left-radius: 0.25rem;
                      border-top-right-radius: 0;
                      border-bottom-right-radius: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .form-builder {
    .component {
      .form-group {
        .input-group {
          #buttondropdown {
            margin-right: unset;
            margin-left: -1px;
          }
        }
      }
    }
  }

  // compact small page
  .page-wrapper {
    .page-body-wrapper {
      .logo-wrapper {
        box-shadow: 9px 0 20px rgba($semi-dark , 0.1) !important;

        a {
          transform: translate(-64px);
        }
      }
    }
  }

  // search page
  .search-page {
    .nav i {
      margin-left: 10px;
      margin-right: unset;
    }
  }

  .product-stts {
    display: inline-block;

    .ms-1 {
      margin-right: 0.25rem !important;
      margin-left: unset !important;
    }
  }

  .search-form input {
    padding: 10px 70px 10px 10px;
    text-align: right;
  }

  // icons page
  .icon-hover-bottom {
    .icon-title {
      text-align: right;
    }
  }

  .icon-lists {
    div {
      i {
        margin: 0 10px 0 15px;
      }
    }

    ul {
      padding-right: 0;
    }
  }

  ul {
    &.icon-lists {
      padding-right: 30px;
    }
  }

  // customizer
  .floated-customizer-btn {
    &.third-floated-btn {
      left: 35px;
      right: unset;
    }

    &>span {
      margin-right: 10px;
    }
  }

  .floated-customizer-panel {
    text-align: right;
    left: -85px;
    right: unset;

    &.active {
      left: 35px;
      right: unset;
    }

    .close-customizer-btn {
      left: 30px;
      right: unset;
    }

    ul {
      padding-right: 0;
    }
  }

  // form builder-2 page
  .form-builder-2-header {
    >div {
      nav {
        float: left;
      }
    }
  }

  .radio {
    label {
      padding-left: 0;
      padding-right: 8px;

      &::before {
        right: 0;
        margin-right: -20px;
        left: unset;
      }
    }
  }

  .input-group.has-validation> :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  // sidebar main page
  .page-wrapper {
    .page-body-wrapper {
      .page-title {
        .row {
          h3 {
            text-align: right;
            padding-left: 20px;
            padding-right: unset !important;
          }

          h6 {
            text-align: right;
          }
        }
      }
    }

    &.compact-wrapper {
      .page-header {
        margin-right: 250px;
        margin-left: unset;
        width: calc(100% - 250px);

        &.close_icon {
          margin-right: 86px;
          margin-left: unset;
          width: calc(100% - 86px);
        }
      }
    }

    &.modern-type {
      .page-header {
        margin-right: 0;
        width: calc(100% - 0px);

        &.close_icon {
          margin-right: 0;
          width: calc(100% - 0px);
        }

        .header-wrapper {
          .logo-wrapper {
            margin-right: unset;
            margin-left: 30px;
          }

          .toggle-sidebar {
            margin-right: unset;
            margin-left: 15px;
          }
        }
      }

      .page-body-wrapper {
        .page-body {
          margin-right: 310px;
        }

        .footer {
          margin-left: 55px;
          margin-right: 360px;
        }

        div.sidebar-wrapper {
          &.close_icon {
            ~.page-body {
              margin-right: 0px;
            }

            ~footer {
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  // page main header
  .page-header {
    .header-wrapper {
      .nav-right {
        text-align: right;

        .onhover-show-div {
          right: unset;
          left: 0;

          @media (max-width:575.98px) {
            left: 12px;
          }
        }

        .cart-dropdown {
          @media (max-width: 575.98px) {
            li {
              padding: 10px;
            }
          }

          .close-circle {
            right: unset;
            left: 0;
          }
        }

        >ul {
          >li {
            .lang-txt {
              margin-left: 0;
              margin-right: 8px;
            }

            &:before {
              right: 0;
              left: unset;
            }

            .dot {
              left: 17px;
              right: unset;
            }

            &.onhover-dropdown {
              &:hover {
                .onhover-show-div {
                  &:before {
                    right: 10px;
                    left: unset;
                  }

                  &:after {
                    right: 10px;
                    left: unset;
                  }
                }
              }

              &:last-child {
                padding-left: 0;
                padding-right: 0 !important;
              }
            }

            &:last-child {
              padding-right: 20px;
              padding-left: 0;
            }

            text-align: right;

            i {
              &.ms-2 {
                margin-right: 0.5rem !important;
                margin-left: unset !important;
              }
            }
          }
        }

        .profile-dropdown {
          li {
            svg {
              margin-right: unset;
              margin-left: 10px;
            }
          }
        }
      }

      .mobile-sidebar {
        padding-left: 20px;
        padding-right: unset;
      }
    }

    &.open {
      margin-right: 0;
    }
  }

  ul {
    &.notification-dropdown {
      &.onhover-show-div {
        right: initial;
        left: -30px;
        padding: 0;

        &:before {
          right: inherit !important;
          left: 35px !important;
        }

        &:after {
          right: inherit !important;
          left: 35px !important;
        }

        li {
          margin-right: 0 !important;

          span {
            &.badge {
              margin-left: unset !important;
            }

            svg {
              margin-right: unset;
              margin-left: 10px;
            }
          }

          .notification-icon {
            margin-left: 20px;
            margin-right: unset;
          }
        }
      }
    }
  }

  // alert page
  .card-body {
    .alert {
      svg {
        ~p {
          padding-left: unset;
          padding-right: 20px;
        }
      }
    }
  }

  .alert {
    i {
      margin-left: 5px;
      margin-right: 0;
    }
  }

  .inverse {
    padding: 13px 65px 13px 20px;

    i {
      left: unset;
      right: 0;
    }
  }

  @each $var in $alert-name {
    $i: index($alert-name, $var);

    .alert-#{$var}.inverse {
      &:before {
        border-right: 7px dashed nth($alert-color, $i);
        right: 54px;
        left: unset;
        border-left: unset;
      }
    }
  }

  .alert-dismissible {
    .btn-close {
      left: 0;
      right: unset;

      span {
        padding-left: 0;
        padding-right: unset;
      }
    }
  }

  .icofont {
    &.icofont-truck {
      display: inline-block;
    }
  }

  // blog page
  .blog-box {
    .blog-details {
      padding-left: 20px;
      text-align: right;

      .blog-social {
        padding-right: 0;

        li {
          padding-left: 40px;
          padding-right: unset;

          &:first-child {
            border-left: 1px dashed #777777;
            border-right: unset;
            padding-left: 40px;
            padding-right: unset;
          }

          &+li {
            margin-right: 30px;
            margin-left: unset;
            padding-left: 0;
          }
        }
      }
    }

    .blog-details-main {
      .blog-social {
        li {
          border-left: 1px dotted;
          border-right: unset;

          &:last-child {
            border-left: none;
          }
        }
      }
    }

    .blog-date {
      span {
        font-size: 36px;
        font-weight: 500;
        padding-left: 5px;
      }
    }
  }

  .comment-box {
    .d-flex {
      img {
        margin-left: 45px;
        margin-right: unset;
      }

      h6 {
        text-align: right;
      }
    }

    ul {
      ul {
        margin-right: 135px;
        margin-left: unset;
        padding-right: 0;

        @media (max-width: 575.98px) {
          margin-right: 30px;
        }
      }
    }

    .comment-social {
      text-align: left;

      li {
        padding-left: 0;
        padding-right: 20px;

        &:first-child {
          border-left: 1px dashed $light-semi-gray;
          border-right: unset;
          padding-right: 0;
          padding-left: 20px;
        }
      }
    }
  }

  // button builder page
  .button-builder-wrap {
    .form-group {
      .btn {
        margin-left: 15px;
        margin-right: 0;
        padding: 9px;
      }
    }
  }

  .button-builder {
    ul {
      &.the-icons {
        padding-right: 0 !important;
      }
    }
  }

  // chat page
  .call-chat-body {
    .chat-box {
      .chat-right-aside {
        .chat {
          .chat-history {
            .caller-img {
              &.ps-0 {
                padding-left: 15px !important;
              }
            }
          }
        }
      }
    }
  }

  .chat-box {
    .user-image {
      float: right;
      margin-left: 5px;
      margin-right: 0;
    }

    .people-list {
      .search {
        i {
          left: 10px;
          right: unset;
        }
      }
    }

    .about {
      float: right;
      padding-right: 10px;
      padding-left: unset;
      text-align: right;
    }

    .chat-menu {
      border-right: 1px dashed $light-color;
      border-left: unset;

      .nav {
        padding-right: 0;
      }

      &.ps-0 {
        padding-left: 15px !important;
      }
    }

    .chat-right-aside {
      .chat {
        .chat-message {
          .smiley-box {
            margin-left: 0.5rem;
            margin-right: unset;
          }

          .text-box {
            .btn {
              border-radius: 5px 0 0 5px;
            }
          }
        }
      }

      &.pe-0 {
        padding-right: 15px !important;
      }
    }

    .chat {
      .chat-message {
        .text-box {
          .input-group-append {
            margin-right: -2px;
          }
        }
      }
    }
  }

  .chat-msg-box {
    ul {
      padding-right: 0;
    }
  }

  .chat-left-aside {
    .status-circle {
      left: unset;
      right: 40px;
    }
  }

  .chat-right-aside {
    .chat {
      .chat-header {
        .chat-menu-icons {
          padding-left: 0;
          padding-right: unset;

          &.float-sm-right {
            float: left !important;
          }
        }

        img {
          float: right;
        }
      }
    }
  }

  // cke editor page
  .cke_toolbar,
  .cke_toolgroup,
  a.cke_button,
  .cke_combo_text {
    float: right;
  }

  .cke_reset_all,
  .cke_reset_all *,
  .cke_reset_all a,
  .cke_reset_all textarea {
    text-align: right;
  }

  .cke_combo_text {
    padding-left: unset;
    padding-right: 10px;
  }

  #editor1 {
    #cke_editor1 {
      #cke_1_contents {
        iframe {
          html {
            direction: rtl !important;
          }
        }
      }
    }
  }

  // coming soon page
  #clockdiv {
    ul {
      padding-right: 0;
    }
  }

  // social app page
  .custom-card {
    .card-footer {
      &>div {
        &+div {
          border-right: 1px dashed $light-semi-gray;
          border-left: unset !important;
        }
      }
    }
  }

  .card-social {
    padding-right: 0;
  }

  .card-absolute {
    .card-header {
      right: 15px;
      left: unset;
    }
  }

  // datatables page
  .dataTables_wrapper {
    .dataTables_filter {
      input[type="search"] {
        margin-right: 10px;
        margin-left: 0;
      }
    }

    .dataTables_length {
      float: right;
    }

    .dataTables_filter {
      margin-left: unset;
      margin-right: 15px;
      float: left;
    }

    .dataTables_info {
      float: right;
    }

    .advance-5_filter {
      margin-left: 0;
    }

    .btn-group {
      button {
        margin-left: 5px;
        margin-right: unset;

        &:last-child {
          margin-left: 0;
        }
      }
    }

    table {
      &.dataTable {
        tbody {

          td,
          th {
            &.select-checkbox {
              &:after {
                margin-left: 31px;
              }
            }
          }
        }
      }
    }
  }

  div {
    &.dataTables_wrapper {
      div {
        &.dataTables_paginate {
          margin-right: 15px !important;
          margin-left: 0 !important;
          float: left;
        }
      }
    }
  }

  table {
    &.dataTable {
      thead {
        &>tr {
          &>th {
            padding-left: 30px;
            padding-right: 12px;

            &:before {
              left: 1em !important;
              right: unset;
            }

            &:after {
              left: 0.5em !important;
              right: unset;
            }
          }
        }
      }
    }
  }

  table.dataTable thead .sorting:before,
  table.dataTable thead .sorting_asc:before,
  table.dataTable thead .sorting_desc:before,
  table.dataTable thead .sorting_asc_disabled:before,
  table.dataTable thead .sorting_desc_disabled:before {
    left: 1em !important;
    right: unset;
  }

  .dataTables_scrollHeadInner {
    padding-right: unset !important;
    padding-left: 0 !important;
  }

  div {
    &.table-responsive {
      &>div {
        &.dataTables_wrapper {
          &>div {
            &.row {
              &>div[class^="col-"]:first-child {
                padding-right: 0;
                padding-left: 0;
              }

              &>div[class^="col-"]:last-child {
                padding-right: 0;
                padding-left: 0;
              }
            }
          }
        }
      }
    }
  }

  .dt-plugin-buttons {
    button {
      &.m-r-10 {
        margin-left: 10px;
        margin-right: 0;
      }
    }
  }

  // datetime picker page
  .datetime-picker {
    .theme-form {
      .form-group {
        label {
          text-align: left !important;
        }

        .input-group-text {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        .input-group {
          .form-control {
            border-radius: 0.25rem;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }
    }
  }

  // dropdown page
  .dropdown-basic {
    .btn {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 0.25rem !important;
      border-bottom-right-radius: 0.25rem !important;
    }

    .separated-btn {
      .btn {
        border-radius: 0.25rem;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }

    .btn-group {
      margin-right: unset;
      margin-left: 18px;
    }
  }

  // error page
  .error-wrapper {
    .col-md-8 {
      &.offset-md-2 {
        margin: 0 auto !important;
      }
    }
  }

  // product page
  .product-price {
    del {
      padding-left: 20px;
      padding-right: 0;
    }
  }

  .product-box {
    .modal {
      .modal-header {
        .btn-close {
          right: unset;
          left: 15px;
        }
      }
    }

    .ribbon-bookmark.ribbon-vertical-left.ribbon-info:before,
    .ribbon-bookmark.ribbon-vertical-right.ribbon-info:before {
      border-left-color: #007bff;
    }

    .ribbon-bookmark.ribbon-vertical-left:before,
    .ribbon-bookmark.ribbon-vertical-right:before {
      right: 0;
      left: unset;
      border-left: 15px dashed #2a3142;
    }

    .ribbon-bookmark.ribbon-info:before {
      border-left-color: transparent;
    }

    .ribbon-warning.ribbon-clip:before {
      border-left-color: #ff850d;
      border-right-color: unset;
    }

    .ribbon-clip {
      right: -14px;
      left: unset;

      &:before {
        right: 0;
        left: unset;
      }
    }
  }

  .product-hover {
    ul {
      padding-right: 0;
    }
  }

  .customer-review {
    .d-flex {
      img {
        margin-left: 25px;
        margin-right: 0;
      }
    }
  }

  .nav {
    padding-right: 0;
  }

  .custom-tab {
    padding: 4px;
  }

  .custom-file-label {
    &::after {
      left: 0;
      right: unset;
    }
  }

  // form wizard 4 page
  .wizard-4 {
    ul {
      &.anchor {
        float: right;
      }
    }

    .step-container {
      clear: unset;
    }

    .form-group {
      text-align: right;
    }

    .action-bar {
      .btn {
        float: left;
      }
    }
  }

  .f1 {
    .f1-buttons {
      text-align: left;
    }

    .f1-steps {
      .f1-step {
        float: right;
      }
    }
  }

  // tree view page
  .jstree-default {
    .jstree-node {
      margin-left: unset;
      margin-right: 24px;
      background-position: -292px -4px;
      background-repeat: repeat-y;
    }
  }

  // image cropper page
  .img-cropper {
    .btn-group {
      margin-right: 0;
      margin-left: 10px;

      button {
        &:last-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-top-left-radius: 0.25rem;
          border-bottom-left-radius: 0.25rem;
        }

        &:first-child {
          border-top-right-radius: .25rem;
          border-bottom-right-radius: .25rem;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
  }

  .docs-toggles {
    .btn-group {
      .btn {
        border-radius: 0;

        &:last-child {
          border-top-left-radius: 0.25rem;
          border-bottom-left-radius: 0.25rem;
        }

        &:first-child {
          border-top-right-radius: 0.25rem;
          border-bottom-right-radius: 0.25rem;
        }
      }
    }
  }

  .btn-group {
    >.btn:not(:last-child):not(.dropdown-toggle) {
      >.btn-group:not(:last-child)>.btn {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  .jsgrid-button {
    +.jsgrid-button {
      margin-right: 5px;
      margin-left: unset;
    }
  }

  .list-group {
    padding-right: 0;

    i,
    img {
      margin-left: 10px;
      margin-right: 0;
    }
  }

  // authentication page
  .authentication-box {
    text-align: right;
  }

  // mega options page
  .mega-horizontal {
    .offset-sm-3 {
      margin-right: 25%;
      margin-left: unset;
    }
  }

  // navs page
  .navs-icon {
    svg {
      margin-left: 10px;
      margin-right: unset;
    }

    .fa {
      margin-left: 10px;
      margin-right: unset;
    }
  }

  .nav-list {
    .nav-list-disc {
      text-align: right;
      padding-right: 0;
    }
  }

  .pl-navs-inline {
    padding-right: 30px !important;
    padding-left: 0 !important;
  }

  .onhover-dropdown.navs-dropdown:hover .onhover-show-div:before {
    left: 77px;
    right: unset;
  }

  .onhover-dropdown.navs-dropdown:hover .onhover-show-div:after {
    left: 77px;
    right: unset;
  }

  .navs-dropdown {
    .onhover-show-div {
      .navs-icon {
        li {
          text-align: right;
        }
      }
    }
  }

  .navs-icon.default-according.style-1 {
    li {
      button[aria-expanded="true"] {
        &:before {
          left: 2px;
          right: unset;
        }
      }

      button[aria-expanded="false"]:before {
        left: 2px;
        right: unset;
      }
    }
  }

  // search page css
  .search-form {
    .form-group {
      &:after {
        right: 53px;
        left: unset;
      }

      &:before {
        right: 82px;
        left: unset;
      }
    }
  }

  // order history page css
  .order-history {
    table {
      tbody {
        tr {
          td {
            .product-name {
              .order-process {
                .order-process-circle {
                  &:before {
                    right: -15px;
                    left: unset;
                  }
                }
              }
            }
          }
        }
      }

      th,
      td {
        &:first-child {
          text-align: right;
        }
      }
    }
  }

  // product-page
  .product-page-details {
    span {
      padding-right: 15px;
      padding-left: 0;
    }
  }

  .m-checkbox-inline {
    label {
      margin-left: 20px;
      margin-right: 0;
    }
  }

  .form-label-align-right {
    label {
      text-align: left;
    }
  }

  // rating page
  .br-theme-bars-reversed,
  .br-theme-bars-movie,
  .br-theme-bars-1to10,
  .br-theme-bars-square {
    .br-widget {
      .br-current-rating {
        float: right;
      }

      a {
        float: right;
      }
    }
  }

  // ribbons page
  .ribbon-left {
    right: auto;
    left: -2px;
  }

  .ribbon-vertical-left {
    right: auto;
    left: 12px;
  }

  .ribbon-vertical-right {
    right: auto;
    left: 12px;
  }

  // search page
  .star-ratings {
    ul {
      &.search-info {
        padding-right: 0;

        li {
          &+li {
            border-right: 1px dashed #a5afc6;
            border-left: unset;
            padding-right: 8px;
            padding-left: 0;
            margin-right: 8px;
            margin-left: 0;
          }
        }
      }
    }
  }

  // select 2 page
  .select2-container--default {
    .select2-selection--single {
      .select2-selection__arrow {
        left: 10px !important;
        right: unset !important;
      }
    }

    .select2-selection--multiple {
      .select2-selection__choice {
        float: right;
      }
    }
  }

  .selection {
    .select2-selection {
      .select2-search__field {
        text-align: right;
      }
    }
  }

  .select2-results__option {
    text-align: right;
  }

  .editor-statusbar {
    text-align: left;

    span {
      margin-right: 1em;
      margin-left: unset;
    }
  }

  .CodeMirror-scroll {
    margin-left: -30px;
    margin-right: unset;
  }

  .primary-color,
  .yellow-color {
    ul {
      padding-right: 0;
    }
  }

  // steps page
  .u-step-desc {
    text-align: right;
  }

  .u-step-number {
    right: 20px;
    left: unset;

    &~.u-step-desc {
      margin-right: 50px;
    }
  }

  .u-pearl {
    &:after {
      left: 0;
      right: unset;
    }

    &:before {
      right: 0;
      left: unset;
    }
  }

  .u-step-icon {
    float: right;
    margin-left: .5em;
    margin-right: unset;
  }

  // summernote page
  .list-icons {
    padding-right: 0;
  }

  .card-header.note-toolbar {
    .note-color {
      .dropdown-toggle {
        padding-right: 5px;
      }
    }

    .form-group {
      &.draggable {
        text-align: right;
      }
    }

    .form-check {
      padding-right: 24px;
      padding-left: unset;
    }

    form {
      .custom-control {
        padding-right: 24px;
        padding-left: unset;

        .custom-control-label {
          &::after {
            right: 0;
            left: unset;
          }

          &::before {
            right: 0;
            left: unset;
          }
        }
      }
    }
  }

  // input group page
  .dropdown-toggle::after {
    margin-right: 0.255em;
    margin-left: unset;
  }

  // tabbed card page
  .tabbed-card {
    ul {
      left: 22px;
      right: unset;
    }
  }

  // checkbox & radios page
  .checkbox {
    label {
      padding-right: 8px;
      padding-left: unset;

      &::before {
        right: 0;
        left: unset;
        margin-right: -16px;
        margin-left: unset;
      }
    }
  }

  .checkbox-wrapper,
  .radio-wrapper {
    li {
      label {
        padding-right: 0;
      }
    }
  }

  .radio {
    label {
      &::after {
        right: 5px;
        left: unset;
        margin-right: -20px;
        margin-left: unset;
      }
    }
  }
  .comment-box {
    .d-flex {
      h6 {
        span{
          padding-right: 10px;
          padding-left: unset;
        }
      }
    }
  }

  // timeline page
  .cd-timeline-img {
    i {
      right: 45%;
      left: unset;
      margin-right: -12px;
      margin-left: unset;
    }
  }
  .btn-group {
    .btn {
      font-size: 14px;
      padding: 0.375rem 1.3rem;
    }
  }

  .flag-icons {
    div {
      .flex-grow-1 {
        h5 {}
      }
    }
  }

  .cd-timeline-block {
    &:nth-child(even) {
      .cd-timeline-content {
        float: left;
        border-left: 5px solid #eeeeee;
        border-right: unset;

        .cd-date {
          right: auto;
          left: 122%;
          text-align: left;
        }

        &::before {
          right: auto;
          left: 100%;
          border: 15px dashed transparent;
          border-left: 15px dashed #f4f4f4;
        }
      }
    }
  }

  .cd-timeline-content {
    .cd-date {
      right: 122%;
      left: unset;
    }
  }

  // tour page
  .introjs-tooltip {
    right: 0;
    left: unset;
  }

  .introjs-helperNumberLayer {
    right: -16px;
    left: unset;
  }

  .introjs-tooltipReferenceLayer {
    .introjs-tooltip {
      .introjs-tooltiptext {
        text-align: right;
      }

      .introjs-tooltipbuttons {
        text-align: left;

        .introjs-skipbutton {
          margin-left: 5px;
          margin-right: unset;
        }

        .introjs-nextbutton {
          -webkit-border-radius: 0.2em 0 0 0.2em;
          -moz-border-radius: 0.2em 0 0 0.2em;
          border-radius: 0.2em 0 0 0.2em;
        }
      }
    }
  }

  .list-inline-item {
    &:not(:last-child) {
      margin-left: 0.5rem;
      margin-right: unset;
    }
  }

  .like-comment {
    ul {
      &.list-inline {
        padding-right: 0;
      }
    }
  }

  .typeahead {
    text-align: right;
  }

  .listing {
    ul {
      padding-right: 0;

      .icofont {
        float: right;
      }
    }
  }

  // gallery page
  .gallery {
    .hover-5 {
      img {
        margin-right: 30px;
      }

      &:hover {
        img {
          margin-right: 0;
        }
      }
    }
  }

  .lg-outer {
    text-align: right;
  }

  .lg-toolbar .lg-icon {
    float: left;
  }

  #lg-counter {
    padding-right: 20px;
    padding-left: unset;
    float: right;
  }

  // .list-group-horizontal-sm>.list-group-item+.list-group-item {
  //   border-left-width: 1px;
  //   border-right-width: 0px;
  // }

  // .list-group-horizontal-sm>.list-group-item:last-child:not(:first-child) {
  //   border-top-left-radius: var(--bs-list-group-border-radius);
  //   border-bottom-left-radius: var(--bs-list-group-border-radius);
  //   border-top-right-radius: 0px;
  //   border-bottom-right-radius: 0px;
  // }

  // .list-group-horizontal-sm>.list-group-item:first-child:not(:last-child) {
  //   border-top-right-radius: var(--bs-list-group-border-radius);
  //   border-bottom-right-radius: var(--bs-list-group-border-radius);
  //   border-top-left-radius: 0px;
  //   border-bottom-left-radius: 0px;
  // }
  // .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
  //   border-top-right-radius: var(--bs-list-group-border-radius);
  //   border-bottom-left-radius: 0;
  // }

  // chart widget page
  .chart-widget-top {
    .text-end {
      text-align: left !important;
    }

    .num {
      .ms-1 {
        margin-right: 0.25rem !important;
      }
    }
  }

  .horizontal-list-wrapper {

    .list-group-horizontal-sm,
    .list-group-horizontal-md,
    .list-group-horizontal-lg,
    .list-group-horizontal-xl,
    .list-group-horizontal-xxl {
      .list-group-item {
        &:first-child {
          border-bottom-left-radius: 0;
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
          border-top-left-radius: 0;
        }

        &:last-child {
          border-top-right-radius: 0;
          border-bottom-left-radius: 6px;
          border-top-left-radius: 6px;
          border-bottom-right-radius: 0;
          border-left-width: 1px;
        }
      }
    }
  }

  .bar-chart-widget {
    .earning-details {
      i {
        left: -240px;
      }
    }

    .bottom-content {
      .b-r-light {
        border-left: 1px dashed #eeeeee !important;
        border-right: unset !important;
      }

      .num {
        .ms-1 {
          margin-right: 0.25rem !important;
        }
      }
    }
  }

  // dashboard project page
  .chart-vertical-center {

    #myDoughnutGraph,
    #myPolarGraph {
      width: auto !important;
    }
  }

  .crm-activity {
    ul {
      &.dates {
        li {
          +li {
            border-right: 1px dashed #ddd;
            padding-right: 10px;
            margin-right: 5px;
            border-left: unset;
            padding-left: unset;
            margin-left: unset;
          }
        }
      }
    }
  }

  div {
    &.dt-buttons {
      float: right;
    }
  }

  .dt-button-collection {
    left: -39px !important;
  }

  // dropzone page
  .dropzone {
    .dz-preview {

      .dz-error-mark,
      .dz-success-mark {
        right: 50%;
        margin-right: -27px;
        margin-left: unset;
        left: unset;
      }
    }
  }

  // footer page
  .page-wrapper {
    .page-body-wrapper {
      footer {
        margin-right: 250px;
        margin-left: unset;

        p {
          i {
            margin-right: 5px;
            margin-left: unset;
          }
        }
      }
    }
  }

  .footer-copyright {
    text-align: right;
  }

  .footer-links {
    text-align: left;
  }

  .page-item {
    &:first-child {
      .page-link {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: none;
      }
    }

    &:last-child {
      .page-link {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  // popover page
  .popover.top,
  .popover.bottom {
    >.arrow:after {
      margin-right: -10px;
      margin-left: unset;
    }
  }

  // touchspin page
  .bootstrap-touchspin {
    >.input-group-append {

      >.btn,
      >.input-group-text {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }
    }

    >.form-control,
    >.custom-select {
      &:not(:last-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  // typahead page
  .typeahead {
    span {
      &.twitter-typeahead {
        .tt-suggestion {
          text-align: right;
        }
      }
    }

    .league-name {
      text-align: right;
      margin-right: 5px;
    }
  }

  // call-chat page
  .follow {
    .text-md-right {
      text-align: left !important;
    }
  }

  // vector map page
  .jvectormap-zoomin,
  .jvectormap-zoomout,
  .jvectormap-goback {
    right: 10px;
    left: unset;
  }

  //customizer rtl css
  .customizer-links {
    right: unset;
    left: 0;

    >.nav {
      padding-right: 10px;
    }

    .nav-link {
      &:after {
        right: -10px;
        left: unset;
        border-width: 5px 5px 5px 0;
        border-left-color: unset;
        border-right-color: lighten($primary-color, 25%);
      }

      span {
        right: unset;
        left: -60px;
      }

      &:hover {
        span {
          left: 48px;
        }
      }
    }

    &.open {
      right: unset;
      left: 330px;
      border-radius: 0 8px 8px 0;
    }
  }

  .customizer-contain {
    width: 330px;
    right: unset;
    left: -330px;

    &.open {
      right: unset;
      left: 0px;
    }

    .customizer-header {
      .icon-close {
        left: unset;
        right: 30px;
      }
    }
  }

  // boxes layout rtl css
  .box-layout {
    &.page-wrapper.horizontal-wrapper {
      .page-header {
        padding-right: 250px;
        padding-left: unset;

        &.open {
          padding-right: 0;
        }
      }
    }

    &.page-wrapper {
      &.compact-wrapper {
        .page-body-wrapper {
          .page-body {
            margin-left: unset;
            margin-right: 230px;
          }

          .sidebar-wrapper {
            &.close_icon {
              ~footer {
                width: 1280px;
                padding-left: 0;
                left: 50%;
              }
            }
          }
        }

        .page-header {
          margin-left: auto;
          margin-right: auto;
          padding-left: unset;
          padding-right: 230px;

          &.close_icon {
            padding-right: 100px;
          }
        }
      }
    }
  }

  body.dark-only {
    .custom-card {
      .card-footer {
        >div {
          +div {
            border-right: 1px dashed $dark-card-border;
          }
        }
      }
    }

    .page-wrapper {
      .page-body-wrapper {
        .page-body {
          .card:not(.email-body) {
            .cd-timeline-block {
              &:nth-child(odd) {
                .cd-timeline-content {
                  &::before {
                    border-left: none;
                    border-right-color: $dark-border-color;
                  }
                }
              }

              &:nth-child(even) {
                .cd-timeline-content {
                  &::before {
                    border-left-color: $dark-border-color;
                    border-right: unset;
                  }
                }
              }
            }

            .b-r-light,
            .border-right {
              border-right: none !important;
              border-left: 1px dashed $dark-card-border  !important;
            }
          }
        }
      }
    }
  }

  // responsive css
  @media screen and (max-width: 1660px) {

    // video chat page
    .caller-img {
      left: unset;
      right: 15px;
    }
  }

  @media only screen and (max-width: 1366px) {
    .profile-dropdown {
      &.onhover-show-div {
        right: unset !important;
        left: 0 !important;
      }
    }

    .product-wrapper {
      .product-grid {
        .feature-products {
          margin-right: 0;
        }
      }
    }

    .product-grid {
      margin-right: 0;
    }

    .d-none-productlist {
      margin-left: 10px;
      margin-right: unset;
    }

    .photos {
      ul {
        li {
          &:nth-child(3n) {
            margin-left: 15px;
            margin-right: unset;
          }
        }
      }
    }

    // chat page
    .chat-left-aside {
      .status-circle {
        left: unset;
        right: 22px;
      }
    }
  }

  @media (min-width: 1200px) {
    .float-xl-end {
      float: left !important;
    }

    // chat page
    .chat-box {
      .pe-xl-0 {
        padding-left: 0 !important;
        padding-right: 15px !important;
      }

      .ps-xl-0 {
        padding-right: 0 !important;
        padding-left: 15px !important;
      }
    }

    // vertical page
    .pixelstrap {
      ul {
        a {

          &:hover,
          &:focus,
          &:active,
          &.highlighted {
            .sub-arrow {
              left: 3px;
              right: unset;
            }
          }
        }
      }
    }
  }

  @media (min-width: 992px) {
    .text-lg-start {
      text-align: right !important;
    }
  }

  @media only screen and (max-width: 1199.98px) {
    .jkanban-container {
      .btn {
        margin-left: unset;
        margin-right: 20px;
      }
    }

    .link-section>div>h6:before {
      right: unset;
      left: 10px;
    }

    .page-wrapper {
      .page-header {
        .header-wrapper {
          .nav-right {
            >ul {
              li {
                .submenu {
                  li {
                    &:first-child {
                      padding-right: 15px !important;
                      padding-left: 15px !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .user-profile {
      .hovercard {
        .user-image {
          .share-icons {
            left: 45px;
          }
        }
      }
    }

    .email-wrap {
      .row {
        .col-xl-6 {
          padding-right: 15px;
        }

        .col-xl-3 {
          +.col-xl-3 {
            padding-left: 15px;
          }
        }
      }

      .email-app-sidebar {
        .main-menu {
          &>li {
            text-align: right;

            a {
              i {
                margin-right: unset;
                margin-left: 10px;
              }
            }
          }
        }

        ul {
          padding-right: 0;
        }
      }

      .email-content {
        .email-top {
          .user-emailid {
            &:after {
              float: left;
            }
          }
        }
      }

      .email-right-aside {
        .email-body {
          .pe-0 {
            padding-right: unset !important;
          }
        }

        .radius-left {
          border-top-right-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
          border-radius: 4px;
        }
      }
    }

    .chat-menu-icons {
      text-align: left;
    }

    .chat-box {
      .chat-history {
        .row {
          .col-sm-7 {
            padding-left: 0 !important;
            padding-right: unset !important;
          }
        }
      }
    }

    .chat-menu {
      left: 0;
      right: unset;
    }
  }

  @media only screen and (max-width: 991.98px) {
    .todo {
      .todo-list-wrapper {
        .mark-all-tasks {
          left: 20px;
        }
      }
    }

    .page-wrapper {
      &.compact-wrapper {
        .page-body-wrapper {
          div.sidebar-wrapper {
            &.close_icon {
              transform: translate(285px);

              &:hover {
                transform: translate(285px);
              }
            }

            .logo-wrapper {
              .back-btn {
                float: left;
                transform: rotate(180deg);
              }
            }
          }
        }

        .page-header {
          .header-wrapper {
            .toggle-sidebar {
              margin-right: 0;
              border-right: none;
              padding-right: 8px;
              border-left: none;
              margin-left: 8px;
              padding-left: 8px;
            }
          }
        }
      }

      &.compact-sidebar {
        .page-header {
          margin-right: 0;
        }
      }

      &.material-icon {
        .page-header {
          padding-right: 0;
        }

        .page-body-wrapper {
          .page-body {
            padding-right: 15px;
          }
        }
      }
    }

    .faq-accordion {
      .faq-header {
        svg {
          left: 20px;
        }
      }
    }

    .page-wrapper {
      .page-header {
        .header-logo-wrapper {
          .logo-wrapper {
            img {
              margin-left: 15px;
              margin-right: unset;
            }
          }
        }
      }
    }

    .page-body-wrapper {

      .page-body,
      footer {
        margin-right: 0 !important;
      }
    }

    .main {
      .langChoice {
        left: 20px;
      }
    }

    .cd-timeline-img {
      i {
        right: 50% !important;
      }
    }

    .page-wrapper {
      .page-body-wrapper {
        .page-title {
          .breadcrumb {
            margin-right: 0;
          }
        }

        .page-body {
          min-height: calc(100vh - 60px);
        }
      }

      .page-header {
        margin-right: 0;
      }

      &.compact-wrapper {
        .page-header {
          margin-right: 0 !important;
          margin-left: unset !important;
        }

        .page-body-wrapper {
          &.sidebar-wrapper {
            &.close_icon {
              transform: translate(285px);
            }
          }
        }
      }
    }

    .card-body {
      .value-left {
        margin-left: 20px;
      }
    }

    .bookmark {
      ul {
        li {
          .search-form {
            .form-control-search {
              left: -127px;
            }
          }
        }
      }
    }

    .card {
      .card-header {
        .card-header-right {
          left: 15px;
        }
      }
    }
  }

  @media only screen and (max-width: 991px) and (min-width: 768px) {
    .page-wrapper {
      &.compact-wrapper {
        &.material-type {
          .page-body-wrapper {
            div.sidebar-wrapper {
              left: unset;
              right: 0;
            }
          }
        }
      }
    }
  }

  @media (min-width: 768px) {
    .float-md-end {
      float: left !important;
    }

    .income-wrap {
      .ps-md-0 {
        padding-left: 30px !important;
        padding-right: 0 !important;
      }
    }

    .text-md-end {
      text-align: left !important;
    }

    .offset-md-3 {
      margin-right: 25%;
      margin-left: unset;
    }

    .text-md-start {
      text-align: right !important;
    }

    .ecommerce-widget {
      .text-md-end {
        text-align: left !important;

        ul {
          text-align: left;
        }
      }
    }
  }

  @media only screen and (max-width: 767.98px) {
    .list-persons {
      .profile-mail {
        .email-general {
          ul {
            padding-left: 0;
          }
        }
      }
    }

    .product-wrapper {
      &.sidebaron {
        .product-grid {
          .product-wrapper-grid {
            margin-right: 0;
          }
        }
      }
    }

    .product-sidebar {
      transform: translateX(300px) scaleX(1);

      &.open {
        transform: translateX(0px) scaleX(1);
      }
    }

    .feature-products {
      .filter-toggle {
        margin-right: 10px;
        margin-left: unset;
      }
    }

    .user-profile {
      .hovercard {
        .user-image {
          .share-icons {
            left: 25px !important;
          }
        }
      }
    }

    .ecommerce-widget {
      .text-md-right {
        text-align: left !important;
      }
    }

    .bar-chart-widget {
      .earning-details {
        i {
          left: -147px !important;
        }
      }
    }
  }

  @media (min-width: 576px) {
    .offset-sm-3 {
      margin-right: 25%;
      margin-left: unset;
    }

    .text-sm-end {
      text-align: left !important;
    }

    .float-sm-end {
      float: left !important;
    }

    .me-sm-2 {
      margin-left: 0.5rem !important;
      margin-right: unset !important;
    }
  }

  @media only screen and (max-width: 575.98px) {
    .todo {
      .todo-list-wrapper {
        .mark-all-tasks {
          left: 15px;
        }
      }
    }

    .list-persons {
      .nav-pills {
        .nav-link {
          .d-flex {
            .flex-grow-1 {
              text-align: right;
            }
          }
        }
      }
    }

    .mega-menu {
      padding-left: unset;
      padding-right: 15px;
    }

    .page-wrapper {
      .page-header {
        .header-wrapper {
          .nav-right {
            >ul {
              li {
                margin-right: unset;
                margin-left: 1px;
              }
            }
          }

          .search-full {
            .form-group {
              .close-search {
                left: 12px;
              }
            }
          }
        }
      }

      &.compact-wrapper,
      &.compact-sidebar {
        .page-header {
          .header-wrapper {
            .toggle-sidebar {
              margin-left: 8px;
              padding-left: 8px;
              margin-right: unset;
              padding-right: 8px;
              border-right: unset;
              border-left: none;
            }
          }
        }
      }
    }

    .translate_wrapper {
      &.active {
        .more_lang {
          &.active {
            left: unset;
            right: 12px;
          }
        }
      }
    }

    .page-header {
      .header-wrapper {
        .nav-right {
          >ul {
            >li {
              &:last-child {
                padding-right: 10px;
              }
            }
          }

          &.right-header {
            ul {
              &.notification-dropdown {
                left: unset !important;
                right: 15px !important;
              }
            }
          }
        }
      }
    }

    .faq-accordion {
      .faq-header {
        svg {
          left: 15px;
        }
      }
    }

    .user-profile {
      .hovercard {
        .user-image {
          .share-icons {
            left: 15px !important;

            li {
              margin-left: 0px;
            }
          }
        }
      }
    }

    .main {
      .langChoice {
        left: 15px;
      }
    }

    .page-wrapper {
      .search-form {
        .form-group {
          margin-left: 0;
        }
      }
    }

    .card-body {
      .value-left {
        margin-left: 15px;
      }
    }
  }

  @media only screen and (max-width: 480px) {
    .page-wrapper {
      .page-header {
        .header-wrapper {
          .nav-right {
            .onhover-show-div {
              right: unset;
              left: 12px;
            }
          }
        }
      }
    }

    .knob-chart {
      .chart-clock-main {
        width: unset;

        .clock-small {
          right: 32px;
        }

        .clock-large {
          right: 0;
          left: unset;
        }

        .clock-medium {
          right: 32px;
          left: unset;
        }
      }
    }
  }

  @media only screen and (max-width: 420px) {
    .page-wrapper {
      &.compact-sidebar {
        .page-body-wrapper {
          div.sidebar-wrapper {
            .sidebar-main {
              .sidebar-links {
                li {

                  .sidebar-submenu,
                  .mega-menu-container {
                    right: 112px;
                  }
                }
              }
            }
          }
        }
      }

      &.compact-small {
        .page-body-wrapper {
          div.sidebar-wrapper {
            .sidebar-main {
              .sidebar-links {
                li {

                  .sidebar-submenu,
                  .mega-menu-container {
                    right: 80px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 360px) {
    .prooduct-details-box {
      .close {
        left: 0;
      }
    }

    .social-app-profile {
      .hovercard {
        .user-image {
          .avatar {
            img {
              width: 85px;
              height: 85px;
            }

            margin-top: -47px;
          }
        }
      }
    }

    .social-chat {
      .other-msg {
        margin-right: 15px;
      }
    }
  }

  @media screen and (max-width: 1460px) {
    .sidebar-wrapper {
      .sidebar-main {
        .sidebar-links {
          .simplebar-wrapper {
            .simplebar-mask {
              .simplebar-content-wrapper {
                .simplebar-content {
                  >li {
                    margin-left: 8px;
                    margin-right: unset;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/**=====================
    5.8 Rtl CSS Ends
==========================**/